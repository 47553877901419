.order-processing-section {
  .top-header-op {
    @apply flex p-3 rounded justify-between;
    background-color: #eef3f6;
  }

  .month-year {
    background-position-x: right;
  }

  .select-two-wrapper {
    div[class$="control"] {
      div[class$="singleValue"] {
        @apply text-sm13;
      }

      div[class$="placeholder"] {
        @apply text-sm13 overflow-hidden overflow-ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }

      input {
        box-shadow: none;
      }
    }

    div[class$="menu"] {
      @apply text-sm13;
    }
  }

  .go-back-button {
    @apply flex flex-col text-white rounded pl-4 pr-4 cursor-pointer border-gray-300;
    background: linear-gradient(to bottom, #9ad7ff 0, #40affe 4%, #0e8eea 100%);
    border: 1px solid #31A5F8;
    padding-top: 2px;
    padding-bottom: 2px;
    @screen sm-mx {
      @apply p-0 text-xs;
    }
  }

  .cancel-order-button {
    @apply flex flex-col text-white rounded pl-4 pr-4 cursor-pointer border-gray-300 bg-red-strong border-red-strong;
    padding-top: 2px;
    padding-bottom: 2px;
    @screen sm-mx {
      @apply p-0 text-xs;
    }
  }

  .info-icon {
    @apply text-sm10 text-sas-gray-300;
    line-height: 18px;
  }

  .op-left .five-step .insurance-description span.info-icon {
    @apply text-sm10 text-sas-gray-300;
    line-height: 18px;
  }

  .question {
    .info-icon {
      @apply text-sm10 text-sas-gray-300;
      line-height: 18px;
    }
  }

  .op-left {
    //order process
    .five-step {
      .body-content-wr {
        @apply p-3 rounded-b-lg min-h-[300px];
        border: 1px solid #dae1e6;
      }

      .origin-sender-section {
        @apply pb-1;
        border: 1px solid #dae1e6;
        min-height: 300px;

        .class-circle {
          @apply p-28 flex justify-center;
        }

        &.payment-section {
          min-height: 375px;
          @screen md-mx {
            min-height: 900px;
            border: 2px solid #f9fafb;
          }
          .pickup-time {
            @apply text-sm13;
            color: inherit;
          }

          .phon-country-select {
            button[class^="ReactFlagsSelect-module_selectBtn"] {
              height: 26px;
              border: 1px solid #aeb0af;

              span[class^="ReactFlagsSelect-module_selectFlag"] {
                @apply text-base;
              }

              span[class^="ReactFlagsSelect-module_label"] {
                @apply text-sm13;
              }
            }

            ul[class^="ReactFlagsSelect-module_selectOptions"] {
              div[class^="ReactFlagsSelect-module_filterBox"] {
                input {
                  @apply text-sm13 py-1;
                  border: 1px solid #aeb0af;
                }
              }

              li {
                span[class^="ReactFlagsSelect-module_selectFlag"] {
                  @apply text-base mr-0.5;
                }

                span[class^="ReactFlagsSelect-module_label"],
                span[class^="ReactFlagsSelect-module_secondaryLabel"] {
                  @apply text-sm13;
                }
              }
            }
          }
        }

        .terms-and-condition {
          @apply underline font-medium cursor-pointer;
          color: #2aa2f8;
        }
      }

      .error-clr {
        border: 1px solid red !important;
      }

      .step-line {
        @apply flex justify-between items-center p-2;
        background-color: #e1f0f5;
      }

      .content {
        @apply overflow-hidden;
        padding: 6px;

        &.open {
          @apply mb-2;
          padding: 0.3rem;
          opacity: 1;
          transform: translateY(0);
          border: 1px solid #dce2e7;
        }
      }

      .heading-op-box {
        @apply flex justify-center text-lg font-semibold items-center cursor-pointer;
        background-color: #e1f0f5;
        width: 100%;
      }

      .heading-op {
        @apply text-lg col-span-8 text-center;
        padding-left: 84px;
        @screen md-mx {
          @apply text-center text-md col-span-10 pl-16;
        }
        @screen sm-mx {
          @apply col-span-9 text-left text-sm pl-0;
        }
      }

      .tab-right-side {
        @apply col-span-2;
      }

      .Mui-expanded {
        .icon {
          opacity: 1;
          transform: rotate(180deg);
        }
      }

      .MuiAccordionSummary-content {
        @apply grid grid-cols-9;
        @screen md-mx {
          @apply grid-cols-12;
        }
      }

      .rounded-icons {
        @apply col-span-1 flex justify-evenly items-center;
        @screen md-mx {
          @apply col-span-2;
        }
        @screen xs {
          @apply col-span-3;
        }

        .round-number {
          font-weight: 600;
          width: 24px;
          height: 24px;
          font-size: 16px;
          border: 2px solid #ff9107;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          color: #ff9107;
          float: right;
          margin-left: 5px;
          padding: 0 7px 0 6px;
          display: inline-block;
          font: normal normal normal 18px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;

          &.completed {
            color: #1bb04b;
            border: 2px solid #1bb04b;
          }
        }

        .icon {
          @apply flex cursor-pointer;
          opacity: 0.9;
          transition: opacity 0s ease-in, transform 0.1s ease-in;
        }
      }

      .text {
        background-color: #ffffff;
        width: 100%;
        padding: 0.4rem;
        margin: 0.3rem;
      }

      span.op-arrow {
        svg {
          color: #0d8ee9;
        }
      }

      .op-bg-color-link {
        background: #249ff5;
      }

      .sender-info {
        @apply flex flex-col m-2 p-3 pb-2;
        border: 1px solid #dce2e7;
        background: #ffffff;

        .origin-font {
          @apply pl-1;
          font-size: 14px;
          @screen xs {
            font-size: 10px;
          }
          @screen lg {
            font-size: 11px;
          }
        }

        .find-location {
          @apply rounded w-full block text-center;
          border-width: 1px;
          color: #000;
          background: #e2e6e7;
          border: 1px solid #adafae;
          font-size: 14px;

          &.disabled {
            // background: #8d8d8d;
            pointer-events: none;
          }
        }

        .pickup-time {
          @apply rounded w-full cursor-pointer;
          padding: 1px 43px 1px 7px;
          border: 1px solid #aeb0af;
          color: #9a9a9a;
          font-size: 13px;
        }

        .text-input {
          @apply w-full border rounded text-gray-700 focus:outline-none items-center p-1;
          border: 1px solid #aeb0af;
          font-size: 13px;
        }

        textarea.textarea {
          @apply h-6;
        }

        .sup-req {
          @apply text-lg;
          color: #ff0000;
        }

        .recv-text-parent {
          @apply grid grid-cols-12 mb-2;
          .recv_text {
            @apply text-sm font-semibold;
            color: #ff9108;
            vertical-align: sub;
          }
        }

        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
          @apply p-0;
        }
      }

      .save-continue-btn {
        @apply rounded py-2 px-4 text-white font-medium text-base shadow-sm border mr-2 my-1;
        background: linear-gradient(
                to bottom,
                #ffb964 0,
                #ffae43 8%,
                #ff8f07 100%
        );
        border-color: #de7b00;

        &.mr0 {
          @apply mr-0;
        }
      }

      .save-continue-btn:disabled {
        background: #bdc3c7;
        border-color: #bdc3c7;
      }

      .receiver-statement {
        @apply w-9/12 text-sm font-medium float-right;
        span {
          color: #ff0000;
        }
      }

      .insurance-description {
        span {
          color: #ffa739;
        }

        u {
          color: #2aa2f8;
        }

        .title {
          font-size: 18px;
        }
      }

      .insurance-title {
        color: #2aa2f8;
      }

      .insurance-check {
        @apply pb-6;
        .css-prp {
          color: #17cbf2;
          font-family: arial;
        }

        .con1 {
          display: block;
          position: relative;
          padding-left: 0;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 15px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .MuiCheckbox-root {
            @apply p-0 mr-1.5;
            &.error-check {
              color: #ff0000;
            }
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .checkmark {
              background-image: url("~public/images/insurance_check.png");
            }
          }

          .disabled {
            color: #ab9a9a !important;
          }
        }

        .checkmark {
          position: absolute;
          top: 4px;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: #f0a674;
          border-radius: 10%;
          // &.error{
          //   border: 9px solid red;
          // }
        }

        span {
          color: #ffa739;
        }
      }

      .payment-coverage {
        // @apply px-4;
        &.first {
          width: 26%;
          float: left;
          background: #f9fafb;
          min-height: 372px;
          @screen md-mx {
            width: 100%;
            min-height: 220px;
            margin-bottom: 4px;
          }
          .promo_applied_section {
            @apply flex px-1 justify-between font-medium;
            @screen md-mx {
              @apply pr-4 pl-6;
            }
          }

          .promocode-section {
            @screen md-mx {
              @apply flex flex-col pl-6;
            }
          }

          .use_code {
            @apply flex flex-col;
            padding-top: 9px;
            align-items: center;

            .change_code {
              background: #3990ef;
              color: #fff;
              padding: 6px;
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }

        &.second,
        &.third {
          @apply p-4 relative;
          width: 36%;
          float: left;
          @screen md-mx {
            width: 100%;
            margin-top: 12px;
          }
          .payemtn-font {
            @apply text-sm;
          }

          .horozontal-line {
            width: 1px;
            position: absolute;
            right: 0;
            top: 0;
            height: 80%;
            margin: 10% 0;
            background: linear-gradient(
                    to bottom,
                    #fff 0,
                    #dae1e6 51%,
                    #fff 100%
            );
            @screen md-mx {
              width: 98%;
              top: 263px;
              height: 1px;
              left: 4px;
            }
          }
        }

        &.third {
          padding-right: 0;

          .css-1d8n9bt {
            padding: 2px 5px;
          }

          .css-tlfecz-indicatorContainer {
            padding: 8px 3px;
          }

          .css-14el2xx-placeholder {
            font-size: 13px;
            line-height: 15px;
          }
        }

        .fromgroup {
          @apply grid grid-cols-12 mb-2;
        }

        .total-fee-section {
          @apply font-semibold pt-5 pl-10;
          @screen lg {
            @apply pl-2;
          }
          @screen md-mx {
            @apply grid pt-4 pl-0 grid-cols-12 text-sm;
          }
          .total-fee {
            @screen md-mx {
              @apply col-span-7 pl-4;
            }
          }

          .price-total {
            color: #ffa739;
            @screen md-mx {
              @apply col-span-5 pr-4 text-right;
            }
          }
        }

        .promotion-code {
          @apply mt-3 text-sm;
          width: 83%;
          border: 1px solid #eeeeee;
          color: rgb(36, 31, 31);
          @screen md-mx {
            @apply text-center;
          }
        }

        .app-promotion-code {
          @apply pt-4 text-center;
          span {
            @apply py-2 px-7 rounded cursor-pointer;
            background: #e4e9ea;
            border: 1px solid #aaacab;
            @screen md-mx {
              width: 83%;
              display: inline-block;
            }
            @screen lg {
              width: 83%;
            }
          }
        }

        .text-input {
          @apply p-0  w-full border rounded text-gray-700 focus:outline-none items-center pl-1;
          border: 1px solid #aeb0af;
          font-size: 13px;
        }

        .sup-req {
          @apply text-lg;
          color: #ff0000;
        }

        .pickup-time {
          @apply rounded w-full cursor-pointer;
          padding: 1px 43px 1px 7px;
          border: 1px solid #aeb0af;
          color: #9a9a9a;
        }

        //ayment info default css override
        .css-1s2u09g-control {
          min-height: 27px;
          cursor: pointer;

          .css-319lph-ValueContainer {
            padding: 0 4px;
          }
        }

        .css-1pahdxg-control {
          min-height: 27px;
        }

        .css-6j8wv5-Input {
          @apply p-0 m-0;
        }

        // .css-1hb7zxy-IndicatorsContainer {
        //   display: none;
        // }
        //ending override of payment tab css
        .payment_text {
          @apply text-sm font-semibold;
          color: #ff9108;
          vertical-align: sub;
        }

        .payment_info {
          display: inline-table;
          padding-top: 1.25rem;
          padding-left: 1.25rem;
          width: 71%;
          margin-left: 12px;
          margin-top: 12px;
          @screen sm-mx {
            @apply m-0;
          }
          @screen md-mx {
            width: 100%;
            margin-left: 0;
          }
          .header {
            background: #ddf1f8;
          }

          tr {
            td {
              @apply p-5 text-center;
              border: 1px solid #aeb0af;
              @screen xs {
                padding: 6px;
                font-size: 12px;
              }
              @screen sm {
                padding: 10px;
                font-size: 13px;
              }
              @screen lg {
                padding: 10px;
                font-size: 14px;
              }
            }
          }
        }

        .total_payment {
          @apply pt-2;
          p {
            @apply px-3 text-right text-base;
          }
        }
      }
    }
  }

  .op-right {
    padding-top: 30px;
    color: #464646;
    margin-bottom: 16px;
    @screen md-mx {
      @apply col-span-12;
      grid-row-end: none;
    }
  }

  .error {
    border: 1px solid red !important;
  }

  .sender-form {
  }
}

.right-element {
  .element-header {
    @apply flex border p-5;
    background-color: #eef3f6;
    border-radius: 4px 4px 0 0;

    .title {
      @apply text-base font-semibold pl-2;
    }

    .close-item-button {
      @apply absolute top-0 right-0 p-2 text-red-400;
      &:hover {
        @apply cursor-pointer text-red-600;
      }
    }
  }

  .element-body {
    @apply border p-4;
    border-top: none;

    &.item_popup {
      @apply m-6;
      border: 1px solid #dae1e6;

      @screen xs {
        //@apply max-h-56 overflow-hidden;
      }
      &.full-text-show {
        @screen xs {
          //@apply max-h-full overflow-auto;
        }
      }
    }

    .small-medium {
      @apply font-medium;
      font-size: 15px;
      color: #2aa2f8;
    }

    .lebel-deliver {
      @apply border mt-6 text-center font-medium rounded text-sm;
      padding: 12px 18px;
      background: linear-gradient(to bottom, #fff 0, #edf5f7 4%, #d8d8d8 100%);
      border: 1px solid #aaacab;
    }

    table {
      @apply w-full;
      tr {
        td {
          @apply text-center text-sm;
          @screen xs {
            padding: 6px;
            font-size: 12px;
          }
          @screen sm {
            padding: 10px;
            font-size: 13px;
          }
          @screen lg {
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }

    .map-location {
      width: 100%;
      padding: 9px 12px;
      border: 1px solid #aaacab;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
      margin-top: 8px;
      font-size: 14px;

      &.dropoff {
        background: linear-gradient(
                to bottom,
                #fff 0,
                #edf5f7 4%,
                #d8d8d8 100%
        );
      }

      &.pickup {
        background: linear-gradient(
                to bottom,
                #ffb964 0,
                #ffae43 8%,
                #ff8f07 100%
        );
        color: #fff;
        padding: 4px;

        &:active {
          background: linear-gradient(
                  to bottom,
                  #fff 0,
                  #edf5f7 4%,
                  #d8d8d8 100%
          );
          color: #000;
        }
      }
    }

    .intruction {
      @apply text-sm block font-semibold mt-4 mb-6 text-center;
      color: #0d8ee9;
    }

    &.process_paragraph {
      p,
      div {
        @apply text-sm font-medium text-center;
        color: #333;
      }
    }
  }

  .down-arrow {
    &::before {
      content: "";
      display: block;
      clear: both;
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #8f969c;
      left: 43%;
    }
  }

  .box-info {
    @apply grid grid-cols-12 pt-7;
  }
}

.dropoffmap {
  @apply inline-block w-6/12 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl;
  height: 600px;
}

.dropoffpopup {
  @apply max-w-full p-8;
  @screen sm-mx {
    @apply p-2;
  }
  @screen xs {
    @apply p-0;
  }
  .close-dropoffpopup {
    @apply absolute top-2 right-2 cursor-pointer text-red-500;
    svg {
      font-size: 20px;

      &:hover {
        @apply text-red-700;
      }
    }
  }

  .dropoff-header {
    h1 {
      @apply text-center text-2xl pb-6;
      background: url(~public/images/popup-header-bg.png) no-repeat bottom center;
      @screen xs {
        @apply text-lg pt-2;
      }
    }
  }

  .map-section {
    @apply flex w-full overflow-auto;
    height: 500px;
    @screen sm-mx {
      @apply flex-col;
    }

    .map-section-left {
      @apply w-1/2 float-left;
      @screen sm-mx {
        @apply w-full;
      }
    }

    .map-section-right {
      @apply w-1/2 overflow-auto float-left;

      @screen sm-mx {
        @apply w-full;
      }
      .location-single {
        //
        @apply cursor-pointer p-1 mb-3 grid grid-cols-12;
        cursor: pointer;
        padding: 4px;
        border: 1px solid #e1e1e1;

        &:hover {
          background: #eee;
        }

        .map-first {
          @apply col-span-2 relative;
          @screen sm-mx {
            @apply col-span-1;
          }
          .fedex-img {
            @screen sm-mx {
              @apply absolute;
              transform: rotate(268deg);
              left: -20px;
              top: 30px;
              max-width: none;
            }
          }
        }

        .map-second {
          @apply col-span-4;
          @screen sm-mx {
            @apply col-span-7;
          }
        }

        .map-third {
          @apply col-span-6 flex justify-around items-center;
          @screen sm-mx {
            @apply flex-col-reverse col-span-4;
          }
          .map-forth {
          }
        }

        .map-distance {
          @apply flex flex-row items-center;
          img {
            @screen xs {
              @apply w-1/3;
            }
          }

          .distance {
            font-size: 14px;
            line-height: 10px;
            // @screen xs {
            //   font-size: 12px;
            // }
          }
        }
      }
    }

    .address {
      font-size: 12px;
      line-height: 16px;
    }

    .map-button {
      @apply px-2 py-4;
      background: linear-gradient(
              to bottom,
              #9ad7ff 0,
              #40affe 4%,
              #0e8eea 100%
      );
      padding: 8px 16px;
      @screen md {
        font-size: 12px;
      }
      @screen xs {
        font-size: 10px;
        padding: 1px 4px;
        margin-right: 6px;
      }
    }
  }
}

.accept-confirm-section {
  @apply text-sm w-6/12 font-normal min-h-[295px];

  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  padding-bottom: 0.5rem;
  border: 1px solid #dce2e7;
  background: #ffffff;
  color: #333333;
  @screen md {
    @apply w-9/12;
  }
  @screen sm-mx {
    @apply w-11/12 mb-2;
  }
}

.org-text {
  color: #ff9107;
}

.insurance-custom-popup {
  @apply p-8;
  max-width: 576px;
  @screen xs {
    @apply p-2;
  }
  &.warehouse-popup {
    @apply w-full pl-2 pr-0;
    width: 100%;
  }

  .insurance-custom-popup-inner {
  }

  .custom-insurance-article-section {
    p {
      @screen xs {
        font-size: 12px;
      }
    }

    .temscond {
      color: #1290ff;
      @screen xs {
        font-size: 12px;
      }
    }
  }

  .insurance-button-section {
    @apply flex justify-end;
    button {
      @apply p-3 ml-2 rounded mt-12;
      color: #ffffff;

      &.reset-insurance {
        background: #646464;
      }

      &.update-insurance {
        background: #ffaa3f;
      }
    }

    @screen xs {
      font-size: 12px;
    }
  }

  .close-button {
    @apply absolute top-2 right-2 cursor-pointer text-red-500 z-10;
    svg {
      font-size: 20px;

      &:hover {
        @apply text-red-700;
      }
    }
  }

  .popup-header {
    @apply relative;
    &.dropoff-header {
      h1 {
        @apply text-lg text-center;
        background: url(~public/images/popup-header-bg.png) no-repeat bottom center;
        @screen sm-mx {
          @apply text-sm;
        }
      }
    }

    h1 {
      @apply text-2xl text-center;
      background: url(~public/images/popup-header-bg.png) no-repeat bottom center;
      padding-bottom: 28px;
    }
  }

  .table-top-section {
    @apply flex justify-between pb-5;
    @screen xs {
      font-size: 11px;
    }
  }

  .custom-insurance-table {
    width: 100%;

    thead {
      background: #ddf1f8;
      color: #64686a;

      th {
      }
    }

    tbody {
      font-size: 14px;

      select {
        font-size: 13px;
        border-radius: 5px;
        border: 1px solid #cecece;
        width: 100%;
        padding: 5px 8px;
        @screen xs {
          font-size: 11px;
          padding: 3px;
        }
      }
    }

    th,
    td {
      border: 1px solid #bcbcbc;
      padding: 14px;
      text-align: center;
      @screen xs {
      }
      @screen sm-mx {
        padding: 4px;
        font-size: 12px;
      }
    }
  }
}

.warehouse-popup-msg {
  @apply text-sm;
  .warehouse-round {
    font-size: 14px;
    border: 3px solid #ff9107;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 1px 7px;
    color: #ff9107;
    font-weight: 700;
    background-color: #f3eded;
  }

  &.thank-you {
    @apply text-center;
    @screen sm-mx {
      @apply text-justify;
    }
  }

  .number {
    @apply font-semibold;
  }
}

.text-org-style {
  color: #ff9108;

  .css-qc6sy-singleValue,
  .css-14el2xx-placeholder {
    color: #ff9108 !important;
  }
}

.css-hz1bth-MuiDialog-container {
  align-items: baseline !important;
}

.map-popup {
}

.insurance_cancel_policy {
  p {
    @apply pb-4 text-sm;
  }

  .below-text {
    @apply text-center pb-2;
    p {
      @apply pb-0;
      a {
        @apply underline font-medium;
        color: #00f;
      }
    }
  }

  .promo-code-change-popup {
    @apply flex pt-2 justify-end;
    button {
      @apply ml-1;
      &:last-child {
        background: #ff9107;
      }
    }
  }
}

.phon-country-select {
  .ReactFlagsSelect-module_selectOptions__3LNBJ {
    width: 200px;
  }

  .ReactFlagsSelect-module_selectBtn__19wW7 {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;

    &:after {
      display: none !important;
    }
  }

  .ReactFlagsSelect-module_selectValue__152eS {
    padding: 0 3px;
    margin: 2px 0;
  }

  .ReactFlagsSelect-module_flagsSelect__2pfa2,
  .ReactFlagsSelect-module_label__27pw9 {
    padding: 0;
    overflow: visible;
  }
}

.label-dialog {
  .label-delivery-section {
    width: 100%;

    .popup-close {
      @apply absolute top-2 right-2 cursor-pointer text-red-500;
      svg {
        font-size: 20px;

        &:hover {
          @apply text-red-700;
        }
      }
    }

    .header {
      h1 {
        @apply text-center text-2xl py-6;
        background: url(~public/images/popup-header-bg.png) no-repeat bottom center;
        color: #333;
        @screen xs {
          @apply text-lg pt-2;
        }
      }
    }

    .label-popup-inner {
      @apply px-10;
      @screen xs {
        @apply px-4;
      }
      .form-top-text {
        @apply grid grid-cols-12;
        @screen sm-mx {
          @apply text-justify;
        }
        p {
          @apply text-sm col-span-12 pb-3;
          color: #333;
        }
      }

      .label-delivery-form {
        @apply pt-12;
        .form-group {
          @apply grid grid-cols-12 text-sm pb-3;
          color: #333;

          .text-style {
            @apply text-sm w-full border rounded text-gray-700 focus:outline-none items-center p-1;
            border: 1px solid #aeb0af;
            color: #333;
          }

          .save-btn {
            @apply rounded py-2 px-4 text-white font-medium text-base shadow-sm border my-1;
            background: linear-gradient(
                    to bottom,
                    #ffb964 0,
                    #ffae43 8%,
                    #ff8f07 100%
            );
            border-color: #de7b00;
          }

          .required {
            @apply text-lg;
            color: #ff0000;
          }

          .select-style {
            @apply cursor-pointer;
            color: #999;

            &:hover {
              color: #333;
              background-color: #e6e6e6;
              border-color: #e1e1e1;
            }
          }
        }
      }
    }
  }
}

.terms-popup {
  h3 {
    &.terms-title {
      @apply text-lg my-3 font-semibold;
      color: #0d8ee9;
    }
  }

  p {
    @apply text-sm pb-3;
    color: #333;

    a {
      color: #00f;
    }
  }

  ul {
    @apply list-disc pl-6 text-sm mb-2;
  }
}

.tracking-dialog {
  [class$="MuiDialog-paper"] {
    width: 60%;
    max-width: 100%;
  }

  .insurance-custom-popup {
    max-width: 100%;

    h1 {
      font-size: 18px;
    }
  }
}

.circle-point {
  color: red;
  border: 1px solid red;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Table */

.item-list-table {
  border: 1px solid #ddd;
}

.item-list-table > thead > tr > th {
  @apply text-xs lg-mn:text-sm text-center font-medium lg-mn:font-semibold px-1 lg-mn:px-[10px] py-2 bg-white-smoke align-middle border-b;
}

.item-list-table > tbody > tr > td {
  @apply text-xs lg-mn:text-sm font-medium lg-mn:font-semibold text-center align-middle border bg-white px-1 lg-mn:px-3 py-2;

  span {
    @apply block w-full;
  }
}


.green-box {
  background-color: #97e0af;
  color: #ff0000;
  padding: 2px;
  font-size: 15px;
  font-weight: bold;
  border: 2px solid #1bb04b;
}

.designed-panel .panel-title {
  display: inline;
}

.designed-panel .panel-footer {
  background-color: #fff;
  border: none;
  padding: 7px 10px;
  border-top: 1px solid #96d0e4;
}