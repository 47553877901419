body{
    @apply font-sans font-normal text-base text-primary;
    @screen md-mx {
        @apply text-sm;
    }
}
p{
    @apply font-sans font-normal text-base text-primary;
    @screen md-mx {
        @apply text-sm;
    }
}

.validation-error {
    border: 1px solid #ff0000 !important;
}