/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Styles Import */
@import './sass/app.scss';

@layer base {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 200 100% 98.82%;
    // --card-foreground: 240 10% 3.9%;
    // --popover: 0 0% 100%;
    // --popover-foreground: 240 10% 3.9%;
    --primary: 205.71 30.39% 13.56%;
    --primary-first: 221.67 95.49% 100%; //dark blue
    --primary-foreground: 355.7 100% 97.3%; // light bg color
    // --primary-foreground2: 238.81 100% 80.02%;
    --secondary: 211.38 85.05% 58.04%; // blue
    --secondary-first: 197.65 73.91% 95.49%;
    --secondary-foreground: 221.74 100% 95.49%; // hero section bg
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 224.82 100% 83.73%;
    // --input: 240 5.9% 90%;
    // --ring: 346.8 77.2% 49.8%;
    --radius: 0.5rem;
    --header-height: 80px;
    --title-color: 211.38 85.05% 58.04%;
    --title-color-foreground: 205.26 85.94% 39.02%;
    --title-home-page: 204.2 100% 51.96%;
    --paragraph-color: 205.71 13.55% 30.39%;
    --tertiary: 32.43 100% 56.47%;
    --tertiary-foreground: 24.09 100% 49.8%;
    --tertiary-home: 24.24 100% 51.96%;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --foreground-rgb: 255, 255, 255;
      --background-start-rgb: 0, 0, 0;
      --background-end-rgb: 0, 0, 0;

      --background: 0 0% 100%;
      --foreground: 240 10% 3.9%;
      --card: 200 100% 98.82%;
      // --card-foreground: 240 10% 3.9%;
      // --popover: 0 0% 100%;
      // --popover-foreground: 240 10% 3.9%;
      --primary: 205.71 30.39% 13.56%;
      --primary-first: 221.67 95.49% 100%; //dark blue
      --primary-foreground: 355.7 100% 97.3%; // light bg color
      // --primary-foreground2: 238.81 100% 80.02%;
      --secondary: 211.38 85.05% 58.04%; // blue
      --secondary-first: 197.65 73.91% 95.49%;
      --secondary-foreground: 221.74 100% 95.49%; // hero section bg
      --muted: 240 4.8% 95.9%;
      --muted-foreground: 240 3.8% 46.1%;
      --accent: 240 4.8% 95.9%;
      --accent-foreground: 240 5.9% 10%;
      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;
      --border: 224.82 100% 83.73%;
      // --input: 240 5.9% 90%;
      // --ring: 346.8 77.2% 49.8%;
      --radius: 0.5rem;
      --header-height: 80px;
      --title-color: 211.38 85.05% 58.04%;
      --title-color-foreground: 205.26 85.94% 39.02%;
      --title-home-page: 204.2 100% 51.96%;
      --paragraph-color: 205.71 13.55% 30.39%;
      --tertiary: 24.09 100% 49.8%;
      --tertiary-foreground: 24.09 100% 49.8%;
      --tertiary-home: 24.24 100% 51.96%;
    }
  }

  /* Styles for the scrollbar */
  ::-webkit-scrollbar {
    @apply w-1 md:w-2;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-secondary backdrop-saturate-50 backdrop-blur rounded-sm;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-primary;
  }

  /* Dark mode */
  @media (prefers-color-scheme: dark) {
    /* Handle */
    ::-webkit-scrollbar-thumb {
      @apply bg-primary/40;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      @apply bg-primary/35;
    }
  }
}

:focus,
:focus-visible {
  outline: none;
}

.multiIMG {
  border: 7px solid #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
// img {
//   width: 110px !important;
//   height: 110px !important;
// }

.carousel {
  height: 100%;
}

.carousel .slide img {
  width: auto;
}

@layer base {
  * {
    @apply m-0 p-0 not-italic;
  }

  section {
    @apply w-full;
  }

  .section-container h1 {
    @apply text-heading-home xl-min:!leading-[48px] font-bold xl-min:!text-5xl text-center md:text-left text-[28px] leading-10 md-mn:mx-0  mx-auto;
  }

  .section-container h1 span:first-of-type {
    @apply text-tertiary-home;
  }

  .section-container h1 span:nth-child(2) {
    @apply text-paragraph block font-semibold xl-min:!text-4xl text-xl md-mn:leading-[28px] lg-mn:leading-[48px] mt-3 lg-mn:mt-0 xl-min:!mt-3;
  }

  .section-container h2 {
    @apply text-heading-foreground font-bold leading-7 md:leading-10;
  }

  .section-container h3 {
    @apply text-heading-foreground text-sm xs:text-xl md:text-2xl;
  }
}

@layer utilities {
  .general-padding {
    @apply md:px-16 px-4 sm:px-6;
  }

  .full-bleed {
    @apply [box-shadow:0_0_0_100vmax_var(--bg)] [clip-path:inset(0-100vmax)];
  }

  .link-styles {
    @apply text-[#0075CB] underline font-extrabold text-lg leading-7;
  }

  .animate-paused {
    animation-play-state: paused;
  }
}
