// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';

@import 'fontawesome/fontawesome';
@import 'fontawesome/brands';
@import 'fontawesome/regular';
@import 'fontawesome/solid';

@import 'mixin';
@import 'base';
@import 'components';
@import '5step';
@import 'countryFlag';
@import 'header';
@import 'sections';
@import 'dashboard';
@import "shipping-tips/shippingTips";


:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  margin: 0;
  padding: 0;
}

body.swal2-shown {
  padding-right:0 !important;
}


.Toastify__toast-container .Toastify__toast .Toastify__toast-body .Toastify__toast-icon {
  margin-top: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.carousel-root {
  .carousel {
    height: auto;
    .slide img {
      width: auto;
      max-height: 450px;
      min-height: 450px;
      @media (max-width: 767px) {
        max-height: 300px;
        min-height: 300px;
      }
    }
  }
}

.select-two-wrapper {
  div[data-value] input {
    @apply focus:shadow-none;
  }
}

.multiIMG {
  position: relative;
  padding-top: calc(100% - 14px);
  overflow: hidden;
  border: 7px solid #fff;
  width: 200px;
}
.multiIMG img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sas-modal .modal-wrapper .close-modal {
  z-index: 10;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 99999 !important;
}

@media (max-width: 1024px) {
  .multiIMG {
    border: 4px solid #fff;
    padding-top: calc(100% - 8px);
    width: 160px;
  }
}
@media (max-width: 767px) {
  .multiIMG {
    border: 2px solid #fff;
    padding-top: calc(100% - 4px);
    width: 100%;
  }
}
