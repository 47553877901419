/* Shipping Tips Page CSS Start */

.shipping-tips-page .section-title {
  @apply flex flex-col justify-center items-center;
}

.shipping-tips-page p {
  font-weight: 400;
}
.shipping-tips-page img {
  max-width: 100%;
}
.shipping-tips-page .how-to-ship {
  padding-top: 60px;
  padding-bottom: 70px;
}
.shipping-tips-page .section-title h2 {
  color: #666c72;
  font-weight: 600;
}
.shipping-tips-page .section-title .sub-title {
  font-size: 15px;
  color: #454545;
}

.shipping-tips-page .feature-box .thumb {
  margin-bottom: 15px;
}
.shipping-tips-page .feature-box .thumb img {
  max-width: 100%;
}
.shipping-tips-page .feature-box .title {
  font-size: 20px;
  color: #0d8ee9;
  margin-top: 0;
  margin-bottom: 18px;
}
.shipping-tips-page .feature-box p {
  font-size: 14px;
  font-weight: 400;
  color: #666c72;
}

.how-to-pack, section.pre-sport-eq, .pro-tips-section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.how-to-pack, section.pre-sport-eq {
  background-color: #f2f5f8;
}

.shipping-tips-page .sub-section-title {
  margin-bottom: 20px;
}
.shipping-tips-page .sub-section-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #0d8ee9;
  margin-top: 0;
  margin-bottom: 10px;
}
.shipping-tips-page .sub-section-title p {
  font-size: 15px;
  font-weight: 600;
  color: #454545;
}

.how-to-pack ul, .shipping-box-section ul{
  padding: 0;
  //margin: 0 0 0 35px;
  list-style: none;
}

.how-to-pack ul li, .shipping-box-section ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  color: #454545;
  font-size: 14px;
  font-weight: 400;
}
.how-to-pack ul li::before, .shipping-box-section ul li::before{
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url('~public/images/list-arrow-icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: 0;
  top: 2px;
}
.how-to-pack .dual-luggage-image, .shipping-box-section .dual-luggage-image {
  display: flex;
  flex-direction: row;
  //flex-flow: wrap;
  justify-content: space-evenly;
  align-items: flex-end;
  gap: 8px;
}
.how-to-pack .bottom-sub-title.sub-section-title,
.shipping-box-section .bottom-sub-title.sub-section-title {
  margin-top: 40px;
}
.how-to-pack .bottom-sub-title.sub-section-title p,
.shipping-box-section .bottom-sub-title.sub-section-title p{
  font-weight: 400;
}

.pro-tips-section .pro-tips-list {
  @apply list-decimal space-y-2 ml-[18px];

  li {
    @apply pl-4 space-y-1;

    p {
      @apply text-[15px];
    }

    :first-child {
      @apply font-medium text-base;
    }

  }

  li::marker {
    @apply font-bold text-sm;
  }

}

.pro-tips-section .tips-for-images {
  img {
    @apply w-full;
    height: 109px;
  }
}

@media only screen and (max-width: 767px) {
  .how-to-pack .dual-luggage-image .item-image ,
  .shipping-box-section .dual-luggage-image .item-image {
    width: 50%;
    text-align: center;
  }
  .shipping-tips-page .section-title .sub-title br{
    display: none;
  }
  .shipping-tips-page .section-title h2 {
    font-size: 24px !important;
  }
  .shipping-tips-page .feature-box .title {
    font-size: 18px;
  }
  .shipping-tips-page .how-to-ship, .how-to-pack, section.pre-sport-eq, .pro-tips-section {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media only screen and (max-width: 480px) {
  .shipping-tips-page .section-title h2 {
    font-size: 24px !important;
  }
  .shipping-tips-page .feature-box .title {
    font-size: 18px;
  }
  .how-to-pack .dual-luggage-image .item-image ,
  .shipping-box-section .dual-luggage-image .item-image {
    width: 50%;
    text-align: left;
  }
  .how-to-pack .dual-luggage-image .item-image:last-child ,
  .shipping-box-section .dual-luggage-image .item-image:last-child {
    text-align: right;
  }
}