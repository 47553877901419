.order-section {
  @apply mt-9;
  .order-column-grid {
    height: 160px;
    background: #dbecf8;
    .sas-selected-clr {
      color: #fa8e2e;
      font-weight: 700;
      &.inc-order {
      }
    }
    .order-img {
      @apply bg-white rounded mt-3 mb-3;
      width: 50px;
    }
    .order-popup-detail {
      color: #2296e7;
      font-weight: 700;
    }
  }
}

// user-statistics-block
.info-statistics-block {
  @apply flex w-full px-5 pt-4 pb-6 rounded-lg border border-sas-yellow-700 bg-white items-center gap-5;
  max-width: 540px;
  @screen lg-mx {
    @apply pl-1 pr-4 pt-3 pb-4;
    width: calc(100% / 2 - 20px);
  }
  @screen md-mx {
    @apply mb-5;
    max-width: 100%;
    width: 100%;
  }
  @screen sm-mx {
    @apply pl-1 pr-4 py-2;
  }
  .icon {
    width: 94px;
    @screen sm-mx {
      width: 74px;
    }
  }
  .right-content {
    @apply flex flex-col pt-4;
    width: calc(100%);
    @screen sm-mx {
      @apply pt-1;
      width: calc(100%);
    }
    ul {
      @apply flex text-sm15;
      @screen lg-mx {
        @apply justify-between;
      }
      @screen sm-mx {
        @apply text-sm11;
      }
      li {
        @apply mr-10;
        @screen lg-mx {
          @apply mr-0;
        }
        @screen sm-mx {
          @apply mr-0;
        }
        &:last-child {
          @apply mr-0;
        }
      }
    }
  }
  &.my-storage {
    @apply border-secondary;
    .right-content {
      .title {
        ul {
          @apply inline-flex ml-4;
          li {
            @apply flex justify-center items-center mr-4;
          }
        }
      }
      ul {
        li {
          @apply mr-6;
          @screen lg-mx {
            @apply mr-3;
          }
          @screen sm-mx {
            @apply mr-0;
          }
          &:last-child {
            @apply mr-0;
          }
        }
      }
    }
  }

  &.incomplete-submit,
  &.shipment-history {
    @apply px-4 pb-4 cursor-pointer border-sas-gray-300;
    @screen sm-mx {
      @apply flex items-center px-0 py-0;
    }
    .right-content {
      h3 {
        @apply text-2xl font-semibold text-center;
        @screen sm-mx {
          @apply text-sm15;
        }
      }
    }
    &.active {
      @apply border-sas-yellow-700;
      .right-content {
        h3 {
          @apply text-primary-orange;
        }
      }
    }
  }
}

// order-list
.order-list {
  .order-item {
    @apply flex justify-between p-3;
    //background-color: #dbecf8;
    background-color: #EEF3F6;
    border-radius: 6px;

    .col-left {
      @apply flex flex-col justify-between min-w-[130px];
      .order-id {
        //text-primary-orange
        @apply block text-sm font-semibold text-secondary underline;
        @screen sm-mx {
          @apply text-sm13;
        }
      }
      .state-info {
        @apply text-sm mt-1;
        @screen sm-mx {
          @apply text-sm13;
        }
      }
      .date {
        @apply text-sm;
        @screen sm-mx {
          @apply text-sm13;
        }
        strong {
          @apply font-medium;
        }
        span {
          @apply font-normal;
        }
      }
    }
    .col-middle {
      @apply flex flex-col text-center justify-between;
      min-width: 115px;

      //@apply grid justify-end;
      //grid-template-rows: repeat(3, minmax(0, 48px));
      //min-width: 105px;

      .status {
        @apply text-lg font-semibold text-primary-orange;
        @screen sm-mx {
          @apply text-sm13;
        }
      }

      .continue-order {
        //text-primary-orange
        @apply block text-sm font-semibold text-secondary underline;
        @screen sm-mx {
          @apply text-sm13;
        }
      }
      .order-detail {
        @apply block text-base font-bold text-secondary underline mt-2;
        @screen sm-mx {
          @apply text-sm13;
        }
      }
      .print-label {
        @apply text-sm font-semibold bg-primary-orange text-white px-2 py-0.5 rounded underline;
        @screen sm-mx {
          @apply text-sm13;
        }
      }
      .status-icon {
        ul {
          @apply flex justify-around items-end w-full;
          li {
            @apply flex flex-col justify-center items-center;
            img {
              height: 30px;
            }
          }
        }
      }
      .date {
        @apply text-sm;
        @screen sm-mx {
          @apply text-sm13;
        }
        strong {
          @apply font-medium;
        }
        span {
          @apply font-normal;
        }
      }
    }
    .col-right {
      @apply grid justify-end;
      grid-template-rows: repeat(3, minmax(0, 48px));
      min-width: 100px;

      ul {
        @apply mt-1.5;
        li {
          @apply text-sm12 text-secondary;
          a {
            @apply text-primary-orange;
          }
        }
      }

      .cancel-order-wr {
        @apply flex justify-end items-end;
      }

      .cancel {
        @apply block text-base font-bold text-red-500 underline mt-2 text-right;
        @screen sm-mx {
          @apply text-sm13;
        }
      }

      .order-details-wr {
        @apply flex justify-end items-start;
      }

      .order-detail {
        @apply block text-base font-bold text-secondary underline mt-2 text-right;
        @screen sm-mx {
          @apply text-sm13;
        }
      }

      .display-date-wr {
        .date {
          @apply text-sm flex justify-center items-center flex-col;
          @screen sm-mx {
            @apply text-sm13;
          }

          span {
            @apply font-normal;
          }
        }
      }
    }
  }
}
//  after-submit-filter
.after-submit-filter {
  @apply inline-flex;
  @screen sm-mx {
    @apply flex flex-wrap justify-center w-full;
  }
  li {
    @apply flex items-center mr-3 cursor-pointer;
    @screen sm-mx {
      @apply flex flex-col w-3/12 mr-0;
    }
    img {
      height: 60px;
      transform: translateY(3px);
    }
    span {
      @apply text-sm font-semibold;
      @screen sm-mx {
        @apply text-sm11;
      }
    }
    &.active {
      span {
        @apply text-secondary;
      }
    }
  }
}

// order-short-details
.order-short-details {
  .details-header {
    h3 {
      @apply text-lg font-semibold;
    }
    h6 {
      @apply text-sm font-medium;
    }
  }
  .info-list {
    li {
      @apply flex mb-1;
      span {
        @apply relative text-sm font-medium block;
        &:first-child {
          width: 70px;
          padding-right: 10px;
          &::after {
            content: ':';
            position: absolute;
            right: 0;
            top: -1px;
          }
        }
        &:last-child {
          @apply pl-3 text-primary-orange;
        }
      }
    }
    &.item-list {
      li {
        @apply mb-7;
        span {
          &::after {
            @apply hidden;
          }
          &:last-child {
            @apply pl-0;
          }
          &.icon {
            img {
              @apply inline-block;
              width: 26px;
            }
          }
        }
        span.sub-item-list {
          @apply pl-0;
          width: calc(100% - 70px);
          span {
            @apply text-primary block w-full;
          }
        }
      }
    }
  }
}

// pagenation
.pagenation {
  @apply list-none flex rounded;
  background-color: #f4f7fc;
  border: 1px solid #dbdbdb;
  li {
    @apply rounded;
    width: 30px;
    height: 30px;
    padding: 2px 0;
    margin-right: 3px;
    &.prev,
    &.next {
      width: 34px;
      padding: 0 0;
      &.has-prev,
      &.has-next {
        background-color: #eaeaea;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      @apply flex justify-center items-center px-3 h-full text-sm15 font-semibold;
      color: #6a6a6a;
      &.active {
        @apply bg-secondary text-white rounded;
      }
    }
  }
}
