//@mixin sas-shadow {
//    box-shadow: 0 16px 56px rgba(84, 127, 243, 0.25);
//}

@mixin btn-primary-hover {
    @apply bg-orange-primary text-white;
}

@mixin btn{
    @apply inline-block bg-gray-400 text-white rounded-sm px-8 py-1.5 transition-all duration-300 ease-in-out;
    @screen sm-mx{
        @apply px-6 py-1;
    }
    &:hover {
        @include btn-primary-hover;
    }
    &.btn-primary {
        @apply bg-secondary text-white;
        &:hover {
            @include btn-primary-hover;
        }
    }
    &.get-started {
        @apply bg-secondary text-white px-10 py-3 rounded-md text-2xl font-semibold;
        @screen sm-mx{
            @apply px-5 py-1.5 text-sm rounded-md;
        }
        &:hover {
            @include btn-primary-hover;
        }
    }
    //&.shadow {
    //    @include sas-shadow;
    //}
}