.header-home-wr {
  &.headerWr_nav {
    min-height: auto;
    //box-shadow: none !important;
    background: #fff;

    .headerWr {
      .headerWr_pages {
        a,
        button {
          color: #0a225f;
          transition: all ease-in-out 0.25s; // Use transition to prevent text leapting
          border-bottom: 2px solid transparent;

          &:hover,
          &:active,
          &.active {
            color: #100aff;
            border-bottom: 2px solid #100aff;
          }
        }
      }

      .sign_area {
        .singIn {
          a {
            width: 87px;
            height: 54px;
            background: #ffffff;
            border: 1px solid #d9dbe9;
            border-radius: 76px;
            color: #170f49;
            font-weight: 400;
            padding: 18px 24px;

            &:hover,
            &:active,
            &.active {
              color: #170f49;
              border-color: #170f49;
            }
          }
        }

        .singUp {
          .singUp_btn-ptm-default {
            width: 138px;
            height: 54px;
            border-radius: 76px;
            border-color: #100aff;
            background: #100aff;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #ffffff;
            flex: none;
            order: 1;
            flex-grow: 0;
          }
          .singUp_btn-ptm-default:hover {
            background: #242265;
            border: 1px solid #d9dbe9;
          }
        }
      }
    }
  }
}

.headerWr_nav {
  min-height: 90px;
  background: #fff;
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.05) !important;
  transition: ease-in-out 300ms !important;

  .headerWr {
    .headerMinHeight {

      .logo-area-wr {
        .headerLogo {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .headerWr_pages {
      justify-content: center;
      gap: 60px;

      button {
        text-transform: capitalize;
        font-size: 16px;
        letter-spacing: 0.015em;
        line-height: 28px;
        color: #403b3b;
        font-weight: 400;
        margin: 0;
        background: transparent;
        padding: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;

        &:hover,
        &:active,
        &.active {
          color: #8789ff;
          background: transparent;
          border-bottom: 3px solid #8789ff;
          border-radius: 0;
          transform: none !important;
        }
      }
    }

    .sign_area {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 19px;

      a {
        text-decoration: none;
      }

      .singIn {
        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.0125em;
          color: #8789ff;

          &:hover,
          &:active,
          &.active {
            color: #0946e8;
          }
        }
      }
    }
  }
}
