@use 'sass:color';

@layer components {
  .trn {
    @apply transition-all duration-1000 ease-in-out;
  }
}

// Link list component
.link-list {
  li {
    @apply mb-2;
    a {
      @apply block;
    }
  }
}

// Social Icons component
.social-icons {
  @apply flex;
  li {
    @apply mr-3;
  }
}

// feature box
.feature-box {
  @apply flex flex-row flex-wrap;
  @screen md-mx {
    @apply mb-6;
  }
  .feature-box-icon {
    @apply text-center mb-3;
    img {
      @apply inline-block;
    }
  }

  .feature-box-content {
    .title {
      @apply text-2xl text-secondary font-semibold mb-4;
      @screen sm-mx {
        @apply text-xl;
      }
    }

    p {
      @apply text-sm;
    }
  }

  &.left-icon {
    @apply flex flex-row flex-wrap;
    .feature-box-icon {
      flex: 0 0 auto;
      max-width: 196px;
      @apply mb-0;
    }

    .feature-box-content {
      width: calc(100% - 196px);
      @apply pl-5;
      @screen xs {
        @apply pl-1.5;
      }
    }

    &.odd {
      .feature-box-content {
        width: calc(100% - 196px);
        @apply pl-0 pr-5;
        @screen xs {
          @apply pr-1.5;
        }
      }
    }
  }

  &.style-two {
    .feature-box-content {
      .title {
        @apply text-lg;
      }
    }
  }

  &.style-three {
    .feature-box-content {
      .title {
        @apply text-lg font-medium text-primary;
      }
    }
  }

  &.style-four {
    .feature-box-content {
      .title {
        @apply text-lg font-semibold mb-3;
      }

      p {
        @apply text-sm;
      }
    }
  }

  &.style-six {
    .feature-box-content {
      .title {
        @apply text-2xl font-semibold mb-3;
        @screen md-mx {
          @apply text-xl;
        }
        @screen sm-mx {
          @apply text-lg;
        }
      }

      p {
        @apply text-lg font-medium;
        @screen md-mx {
          @apply text-base;
        }
        @screen sm-mx {
          @apply text-sm;
        }
      }
    }
  }
}

// storage feature table
.storage-feature-table {
  @apply border border-secondary sm-mx:shadow-none;
  thead {
    @apply bg-secondary;
    tr {
      td {
        @apply text-lg text-white;
        @screen sm-mx {
          @apply text-sm12;
        }
        img {
          @screen sm-mx {
            width: 90%;
          }
        }
      }

      @screen sm-mx {
        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4) {
          width: 60px;
        }
      }
    }
  }

  tr {
    td {
      @apply text-lg text-center py-3 px-5 border-r border-b border-secondary;
      @screen sm-mx {
        @apply py-1 px-1 text-sm12;
      }
      &:first-child {
        @apply text-left;
        @screen xs {
          @apply pl-2;
        }
      }

      img {
        @apply inline-block;
        @screen sm-mx {
          width: 20px;
        }
      }
    }
  }
}

//  swiper client slider
.swiper.client-say-carousel {
  padding-bottom: 60px;

  .slide-item-inner {
    .header {
      @apply relative mb-2;
      min-height: 48px;
      padding-left: 58px;

      .thumb {
        @apply absolute left-0 top-0 bg-white-ice flex justify-center items-center rounded-full;
        width: 48px;
        height: 48px;

        span {
          @apply text-base font-bold leading-5;
        }
      }

      .user-name {
        @apply font-semibold text-lite-color;
      }

      .user-role {
        opacity: 0.7;
      }
    }

    .content {
      opacity: 0.7;
    }

    .user-rating {
      @apply mt-7;
      i {
        @apply text-sas-yellow inline-block mr-3;
      }

      .rating-number {
        @apply font-semibold;
      }
    }
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    @apply flex items-center text-left;
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;

      &.swiper-pagination-bullet-active {
        width: 16px;
        height: 16px;
        border-radius: 50px;
        border: none;
      }
    }

    span.swiper-pagination-bullet-active + span {
      @apply bg-secondary rounded-none;
      width: 40px;
      height: 1px;
      opacity: 1;
    }
  }
}

// icon list
.icon-list {
  @apply flex flex-wrap justify-between mt-9;
  li {
    @apply bg-white relative flex items-center;
    min-height: 50px;
    width: calc(100% / 2 - 10px);
    margin-bottom: 10px;
    padding: 10px 10px 10px 70px;
    @screen sm-mx {
      @apply text-left;
    }
    @screen xs {
      width: 100%;
    }
    .icon {
      @apply absolute;
      width: 40px;
      height: 40px;
      left: 10px;
      top: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.row {
  @apply flex flex-row flex-wrap;
}

.btn-primary-hover {
  @include btn-primary-hover;
}

.btn {
  @include btn;
}

// section title
.section-title {
  @apply relative inline-block;
  h1,
  h2 {
    @apply text-4xl font-bold text-secondary relative mb-2;
    @screen sm-mx {
      @apply text-xl;
      br {
        @apply hidden;
      }
    }
  }

  p {
    @apply text-xl text-primary;
    @screen sm-mx {
      @apply text-sm;
      br {
        @apply hidden;
      }
    }
  }

  &.effect {
    h2 {
      @apply pb-2;
      &::after {
        content: '';
        width: 46%;
        height: 2px;
        bottom: 0;
        left: calc(54% / 2);
        @apply bg-secondary absolute;
      }
    }

    &.effect-after {
      @screen md-mn {
        h2 {
          @apply pb-2;
          &::after {
            content: '';
            width: 46%;
            height: 2px;
            top: 50%;
            bottom: auto;
            left: calc(100% + 50px);
            @apply bg-secondary absolute;
          }
        }
      }
    }
  }
}

// list style
.list-style-one {
  li {
    position: relative;
    padding-left: 28px;

    &::before {
      content: '';
      top: 8px;
      left: 0;
      width: 21px;
      height: 20px;
      position: absolute;
      background-image: url('~public/images/checkbox-icon.png');
      background-repeat: no-repeat;
    }

    @screen sm-mx {
      @apply text-sm;
      br {
        @apply hidden;
      }
    }
  }
}

// promotion-box
.promotion-box {
  z-index: 20;
  transform: translateY(45px);
  @apply inline-block relative;
  @screen sm-mx {
    @apply translate-y-0;
  }
  .promotion-inner {
    @apply absolute w-full h-full top-0 left-0;
    @screen sm-mx {
      @apply relative h-auto top-auto left-auto;
    }
    .promotion-content {
      h2 {
        @apply text-primary-darkblue text-4xl font-semibold leading-10 mb-3;
        @screen sm-mx {
          @apply text-xl;
          br {
            @apply hidden;
          }
        }
      }

      .btn {
        @apply py-3 px-14 rounded-md text-lg font-medium mb-3;
        @screen sm-mx {
          @apply px-5 py-1.5 text-sm rounded-md;
        }
      }

      p {
        @screen sm-mx {
          @apply text-sm;
          br {
            @apply hidden;
          }
        }
      }
    }
  }
}

// info icon
.info-icon {
  @apply text-sm10 font-thin border border-gray-300 rounded-full inline-block text-center inline-flex justify-center items-center cursor-pointer ml-0.5;
  line-height: 19px;
  width: 20px;
  height: 20px;

  &.img-icon {
    width: auto;
    height: auto;
    @apply border-none mb-0;
    img {
      margin-bottom: 0 !important;
    }
  }
}

// .find-storage-form
.find-storage-form {
  @apply py-2.5 px-3.5 bg-white rounded-full overflow-hidden;
  min-width: 530px;
  box-shadow: 21px 0 50px rgba(#4270ec, 0.35);
  z-index: 1;
  @screen sm-mx {
    @apply py-2 px-3;
    width: 80%;
    min-width: auto;
    height: 55px;
  }
  @screen xs {
    @apply py-2 px-3;
    width: 90%;
    min-width: auto;
    height: 50px;
  }
  form {
    height: 100%;

    .input-group {
      @apply relative h-full;
      @screen sm-mx {
        @apply p-0;
      }
      .input-field {
        @apply border-none shadow-none text-primary w-full;
        height: 60px;
        box-shadow: none;

        &:focus {
          border: none;
          box-shadow: none;
        }

        @screen xs {
          @apply text-sm;
        }
        &::placeholder {
          @apply text-primary;
          @screen xs {
            @apply text-sm;
          }
        }

        @screen sm-mx {
          @apply px-0;
          height: 100%;
        }
      }

      button {
        @apply absolute top-0 right-0;
        width: 40%;
        height: 100%;
        background-color: transparent;
        background-image: url('~public/images/btn-bg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @screen sm-mx {
          @apply px-2;
        }
        @screen xs {
          @apply text-sm;
        }
        &:hover {
          background-color: transparent;
          background-image: url('~public/images/btn-bg-orange.png');
        }
      }
    }
  }
}

// find-storage-with-map
.find-storage-with-map {
  .left-col {
    @apply pl-5 pr-10;
    width: 45%;
    @screen xl-mx {
      width: 50%;
    }
    @screen lg-mx {
      @apply pr-5;
      width: 40%;
    }
    @screen md-mx {
      width: 50%;
    }
    @screen sm-mx {
      width: 100%;
    }
  }

  .right-col {
    width: 55%;
    @screen xl-mx {
      width: 50%;
    }
    @screen lg-mx {
      width: 60%;
    }
    @screen md-mx {
      width: 50%;
    }
    @screen sm-mx {
      width: 100%;
    }
  }

  .find-storage-form {
    margin-top: 30px;
    border-radius: 5px;
    height: 70px;
    width: 100%;
    min-width: auto;
    @screen md-mx {
      height: auto;
      margin-top: 20px;
    }
    form {
      .input-group {
        .input-field {
          @screen md-mx {
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        button {
          @apply px-1;
          @screen md-mx {
            height: 40px;
          }
        }
      }
    }
  }

  .storage-list {
    @apply mt-4;
    @screen md-mx {
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .storage-item {
      @apply flex flex-wrap justify-between py-4 border-b border-sas-gray-300;
      &:last-child {
        @apply border-none;
      }

      .left-info,
      .middle-info,
      .right-info {
        width: 35%;
      }

      .left-info {
        width: 30%;
        @apply flex flex-col justify-between;
        @screen lg-mx {
          @apply justify-start;
          width: 50%;
        }
        .top-part {
          h3 {
            @apply text-lg font-semibold;
          }

          p {
            @apply text-sm;
          }
        }

        .bottom-part {
          .storage-rating {
            i {
              @apply inline-block mr-1 text-sm text-yellow-300;
            }

            .rating-number {
              @apply text-sm font-semibold;
            }
          }

          p {
            @apply text-sm;
          }
        }
      }

      .middle-info {
        width: 35%;

        .btn {
          @apply bg-primary-orange px-2 py-2.5 text-md font-bold block rounded-none mb-4;
        }
      }

      .right-info {
        @apply pl-4;
        @screen lg-mx {
          @apply pl-0;
          width: 50%;
        }
        .btn {
          @apply bg-primary-orange px-2 py-1.5 text-md font-bold rounded-none mt-1;
          @screen lg-mx {
            @apply text-sm font-medium text-center;
          }
        }
      }

      .price-list {
        li {
          @apply flex flex-wrap justify-between;
          span {
            @apply text-sm15;
            &.price {
              @apply font-bold;
            }
          }
        }

        &.tab-price-list {
          li {
            span {
              width: 50%;
            }
          }
        }
      }
    }
  }

  .map-view {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// select two
.select-two-wrapper {
  &.has-error {
    div[class$='control'] {
      @apply border-red-600;
    }
  }

  div[class$='control'] {
    &:hover,
    &.css-1pahdxg-control {
      @apply border-blue-300 ring ring-blue-300 ring-opacity-50;
    }

    input {
      box-shadow: none;

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  div[class$='menu'] {
    z-index: 20;
  }
}

//  input field
.input-group {
  label {
    @apply block text-md mb-1;
  }

  .input-field,
  .react-datepicker__input-container input {
    @apply rounded border border-gray-300 shadow-sm py-1.5;
    max-width: 100%;

    &:focus {
      @apply border-blue-300 ring ring-blue-300 ring-opacity-50;
    }

    &::placeholder {
      @apply text-sas-gray-200 text-base;
    }
  }

  .autocomplete-filed {
    @apply relative;
    &.has-error {
      .input-field {
        @apply border-red-600;
      }
    }

    .loading {
      position: absolute;
      right: 2px;
      top: 14px;
    }

    .autocomplete-list {
      @apply absolute mt-1.5 rounded border border-gray-300 bg-white;
      z-index: 10;
      width: 100%;
      max-height: 250px;
      overflow-y: auto;
      left: 0;
      top: 100%;
      @screen xs {
        left: auto;
        right: 0;
      }
      li {
        @apply px-2.5 py-2.5 text-sm font-medium cursor-pointer;
        &:hover {
          @apply bg-gray-300;
        }

        @screen sm-mx {
          @apply text-sm11;
        }
      }
    }
  }

  .error {
    @apply text-red-600 font-bold;
  }
}

// faq-list
.faq-list {
  .faq-item {
    @apply mb-8;
    .title {
      @apply text-3xl font-semibold text-secondary mb-2;
      @screen sm-mx {
        @apply text-xl;
      }
    }

    .faq-content {
      p {
        @apply text-lg;
        @screen sm-mx {
          @apply text-base;
        }
      }

      ul {
        li {
          @apply mb-2;
        }
      }
    }
  }
}

//
#popover-root {
  .popover-hover-content {
    background: #fff;
    @apply px-3 py-3;
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow-y: visible;
    overflow-x: visible;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    z-index: 9999999999999;

    .arrow {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      // margin: 0 0.3rem;
      background: transparent;
      top: calc((0.5rem + 1px) * -1);
      left: calc(100% / 2 - 0.5rem);

      &:before,
      &:after {
        position: absolute;
        display: block;
        content: '';
        border-color: transparent;
        border-style: solid;
      }

      &:before {
        top: 0;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        border-width: 0 0.5rem 0.5rem 0.5rem;
      }

      &:after {
        top: 1px;
        border-bottom-color: #fff;
        border-width: 0 0.5rem 0.5rem 0.5rem;
      }
    }

    .box-info-content {
      @apply text-center;
      img {
        @apply inline-block;
      }

      p {
        @apply text-sm13;
      }

      .go-back-button {
        @apply flex flex-col text-white rounded pl-4 pr-4 cursor-pointer border-gray-300;
        background: linear-gradient(to bottom, #9ad7ff 0, #40affe 4%, #0e8eea 100%);
        border: 1px solid #0968aa;
        padding-top: 2px;
        padding-bottom: 2px;
        @screen sm-mx {
          @apply p-0 text-xs;
        }
      }

      span.sub-items {
        @apply block;
        span {
          @apply block text-sm13 mb-2;
          &:last-child {
            @apply mb-0;
          }
        }
      }
    }
  }
}

// Coming-soon-box
.coming-soon-box {
  @apply absolute left-0 top-0 w-full h-full flex justify-center items-center text-secondary font-semibold;
  background-color: rgba(255, 255, 255, 0.8);
}

// modal css
.sas-modal {
  @apply flex justify-center fixed w-full h-full left-0 top-0;
  z-index: 999999;
  background: rgba(#000000, 0.2);
  padding: 15px;

  .modal-wrapper {
    margin-top: 0;
    @apply mt-5 relative overflow-y-auto;
    //min-width: 400px;
    //max-width: 375px;

    @screen sm-mx {
      min-width: auto;
      width: calc(100% - 15px);
    }
    .close-modal {
      @apply absolute cursor-pointer;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
    }

    .modal-content {
      @apply bg-white p-5;
      border-radius: 12px;
    }
  }

  :focus,
  :focus-visible {
    outline: none;
  }

  // backdrop
  .sas-backdrop {
    @apply fixed w-full h-full left-0 top-0;
    background: rgba(#000000, 0.4);
  }
}

.fullScreen {
  padding: 15px;

  .modal-wrapper {
    min-width: 100%;
    max-width: 1200px;
    width: 100%;
    padding: 0;
    margin-top: 0;
  }
}

// full-screen-loader
.full-screen-loader {
  @apply fixed w-full h-full top-0 left-0 flex justify-center items-center;
  background-color: rgba(#000000, 0.4);
  z-index: 999;

  .loading {
    @apply px-5 rounded bg-white shadow-sm;
  }
}

//
.Toastify__toast-container {
  min-width: 450px;
  min-height: 60px;

  .Toastify__toast {
    min-width: 500px;
    min-height: 60px;
    border-radius: 0;
    border-left: 4px solid #ffffff;
    padding: 15px 15px;

    &.Toastify__toast--success {
      border-color: #47d764;
    }

    &.Toastify__toast--error {
      border-color: #f78f8f;
    }

    &.Toastify__toast--info {
      border-color: #1d9fe4;
    }

    &.Toastify__toast--warning {
      border-color: #ffc021;
    }

    .Toastify__toast-body {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      align-items: flex-start;

      .Toastify__toast-icon {
        margin-top: 10px;
      }

      div:last-child {
        .title {
          @apply text-xl font-bold text-primary;
        }

        p {
          @apply text-sm font-normal;
        }

        ul {
          li {
            @apply text-sm font-normal;
          }
        }
      }
    }
  }
}

.calender-icon {
  .react-datepicker__input-container {
    input {
      z-index: 1;
      position: relative;
      background: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 22px;
      top: 7px;
      right: 7px;
      z-index: 0;
      background-image: url('~public/images/calander-icon.png');
      background-size: 100% 100%;
      background-position: center;
    }
  }
}

input[type='time']::-webkit-calendar-picker-indicator {
  content: '';
  // position: absolute;
  width: 20px;
  height: 22px;
  z-index: 0;
  background-image: url('~public/images/clock-icon.png');
  background-size: 100% 100%;
  background-position: center;
}

.modal-scroll::-webkit-scrollbar {
  width: 4px;
}

.carousel {
  height: 100%;
}

.link-like-button {
  @apply bg-secondary py-2 lg-mn:py-3 px-6 rounded-md leading-relaxed text-white text-center text-lg font-medium inline-flex justify-center items-center;

  &:hover {
    @apply bg-primary-orange;
  }
}

.parsed-content-styles {
  ul,
  ol {
    @apply pl-6 lg-mn:pl-10 text-left;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  a {
    @apply underline text-secondary font-semibold;
  }

  .will-have-scrollbar {
    @apply overflow-x-auto;

    table {
      @apply min-w-[1000px];
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      //background-color: #dddddd;
    }
  }
}
