header {
  //background-image: url("~public/images/navbar-bg.png");
  @apply bg-cover bg-no-repeat;
}

.home-banner {
  background-image: url('~public/images/Home-header-bg.png');
  @apply bg-cover bg-no-repeat;

  @screen sm-mx {
    .banner-image {
      //margin-bottom: -17%;
    }

    & + .banner-after-content, & + .sidebar-menu + .banner-after-content {
      //padding-top: 13%;
    }
  }
}

.book-now-header {
  // background-image: none;
}

.white-ice-two {
  background-image: url("~public/images/navbar-bg.png");
  @apply bg-cover bg-no-repeat;
}

.residential-storage-banner {
  background-image: url('~public/images/residential-storage-banner.png');
  @apply bg-cover bg-no-repeat;
  background-position: bottom center;
  @screen md-mx {
    background: none;
    @apply pb-16;
    //background-size: auto 100%;
    //background-position: 0% 100%;
  }
  @screen sm-mx {
    @apply pb-16;
    background-size: cover;
    background-position: bottom center;
  }
  @screen xs {
    background-size: auto 100%;
    background-position: 0 100%;
    background-image: url('~public/images/residential-storage-banner-mobile.png');
  }
}

.moving-storage-banner {
  background-image: url('~public/images/moving-storage-banner.png');
  @apply bg-no-repeat bg-white-ice-three;
  background-position: bottom center;
  background-size: 100% auto;
  @screen sm-mx {
    @apply pb-20;
  }
}

.location-storage-banner {
  background-image: url('~public/images/location-storage-banner.png');
  @apply bg-no-repeat bg-white-ice-three;
  background-position: bottom center;
  background-size: 100% auto;
  @screen sm-mx {
    @apply pb-20;
  }
}

.location-box-storage-banner, .primary-banner-wrapper {
  // background-image: url('~public/images/luggage-&-box-shipping.png');
  background-image: none;
  @apply bg-no-repeat bg-white-ice-three;
  background-position: bottom center;
  background-size: 100% auto;

  .main-banner {
    @apply relative;
    .banner-image-left, .banner-image-right {
      @apply absolute left-0 bottom-0;
      max-width: 18%;
    }

    .banner-image-left {
      max-width: 16%;
      left: 20px;
    }

    .banner-image-right {
      @apply left-auto right-0;
    }
  }
}

.user-dashboard-header {
  @apply bg-white-ice-three;
  background-image: none;
}

.navbar {
  .navebar-brand {
    @screen lg {
      max-width: 138px;
    }
    @screen sm-mx {
      img {
        max-width: 100px;
      }
    }
    &.user-mobile-brand {
      @screen sm-mx {
        width: 100px;
      }
    }
  }

  .mobile-buttons {
    @apply flex flex-col items-center hidden;
    @screen lg-mx {
      @apply flex;
    }
    a.contect-number {
      @apply text-sm15 font-bold inline-block relative;
      padding-left: 15px;
      color: #3c4750;

      &::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 17px;
        top: 1px;
        left: -8px;
        background-image: url('~public/images/phone-icon-3.png');
        background-size: 100% 100%;
      }
    }

    a.get-a-quote {
      @apply bg-secondary text-white px-4 py-1 rounded-lg text-sm;
      &:hover {
        @apply bg-primary-orange;
      }
    }
  }

  .main-menu {
    @apply flex flex-row flex-wrap;
    li {
      @apply flex flex-col justify-center px-5 py-1.5;
      @screen lg {
        @apply px-2.5;
      }
      &.about-us {
        @apply hidden;
      }

      a, button {
        @apply block text-sm15 font-medium text-primary text-center transition-all duration-300 ease-in-out;
        &:hover {
          @apply text-secondary;
        }

        &.active {
          @apply text-secondary;
        }

        &.btn {
          @include btn;
          @apply rounded-xl;
        }

        &.contact-item {
          @apply px-0 py-0.5;
        }

        &.contect-number {
          @apply text-sm15 font-bold inline-block relative;
          color: #3c4750;

          &::before {
            content: "";
            position: absolute;
            width: 19px;
            height: 17px;
            top: 1px;
            left: -22px;
            background-image: url('~public/images/phone-icon-3.png');
            background-size: 100% 100%;
          }
        }

        &.btn.btn-primary.login {
          @apply px-3 py-0 bg-primary-orange rounded text-base;
          &:hover {
            @apply bg-secondary;
          }
        }

        span.icon {
          display: flex;
          justify-content: center;
        }
      }

      a {
        @screen xl-min {
          @apply flex flex-col gap-1;
        }
      }

      &.get-a-quote {
        @apply py-1.5 flex justify-center;
        a {
          @apply bg-secondary text-white px-3 py-1.5 rounded-lg text-sm;
          &:hover {
            @apply bg-primary-orange;
          }
        }

        @screen md-mx {
          @apply hidden;
        }
      }

      .user-menu {
        @apply relative;
        &:hover {
          button {
            border-radius: 3px 3px 0 0;
          }

          ul {
            display: block;
          }
        }

        button {
          @apply px-7 py-1;
          border-radius: 3px 3px 3px 3px;
        }

        ul {
          @apply absolute bg-white shadow w-full;
          border-radius: 0 0 3px 3px;
          top: 30px;
          right: 0;
          display: none;
          z-index: 1;
          min-width: 100px;

          li {
            @apply text-left px-3;
            a {
              @apply text-left;
            }
          }
        }
      }
    }

    &.user-main-menu {
      li {
        @apply justify-start pt-1;
        a[disabled] {
          opacity: 0.5;
        }

        // a {
        //     @apply flex flex-col items-center;
        //     .icon {
        //         @apply text-center flex flex-col;
        //         width: 40px;
        //         height: 40px;
        //         background-image: url('~public/images/dashboard/icon/icon-backgorund.png');
        //         background-size: 100% 100%;
        //         background-repeat: no-repeat;
        //         img {
        //             display: inline-block;
        //             max-width: 35px;
        //         }
        //     }
        // }
        &.profile {
          @apply justify-start;
        }

        &.auth-btns {
          @apply pr-0 items-end;
          .logout {
            @apply px-6;
            border-radius: 3px;
            max-width: 100px;
            margin-bottom: 5px;
          }

          .new-order {
            @apply px-5 bg-primary-orange;
            border-radius: 3px;
            max-width: 160px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .mobile-menu-arrow {
    @apply hidden text-3xl bg-white text-secondary px-4 rounded-2xl transition-all duration-300 ease-in-out;
    @screen lg-mx {
      @apply flex justify-center items-center ;
      height: 36px;
      width: 60px;
    }
  }

  //
  .user-main-menu {
    li {
      @screen lg {
        @apply px-1;
      }
    }

    &.mobile-auth-menu {
      @apply flex flex-col;
      width: calc(100% - 200px);
      @screen sm-mx {
        width: calc(100% - 100px);
      }
      li {
        @apply w-full;
        &.auth-btns {
          @apply flex flex-row justify-end;
          button.logout {
            @apply ml-2;
          }

          @screen xs {
            @apply pl-1;
            a.new-order, button.logout {
              @apply text-sm12;
            }

            button.logout {
              @apply ml-1;
            }
          }
        }
      }
    }

    &.mobile-main-menu {
      @apply justify-between;
      li {
        @screen sm-mx {
          @apply px-2;
        }
        @screen xs {
          @apply px-0;
        }
        &:first-child {
          @apply w-full flex flex-row justify-between;
        }

        a {
          @apply flex flex-col-reverse text-sm12;
          @screen xs {
            @apply text-sm11;
          }
        }
      }
    }
  }
}

.sidebar-menu {
  @apply fixed w-full h-full top-0 left-0;
  background-color: rgba(84, 127, 243, 0.25);
  z-index: 999;

  .navbar {
    @apply bg-white border border-secondary rounded-lg mt-3 w-11/12 ml-auto mr-auto pt-14 relative;
    .mobile-menu-close {
      @apply absolute right-5 top-3 text-2xl;
      opacity: 0.5;
    }

    ul {
      @apply block;
      li {
        @apply block;
        &.about-us {
          @apply block;
        }

        a {
          @apply px-2 flex items-center text-left;
          span {
            @apply inline-block;
            &.icon {
              @apply mr-3;
            }
          }

          br {
            @apply hidden;
          }
        }
      }
    }

    .menu-footer {
      @apply bg-white-ice text-center py-3 px-2 rounded-lg mt-6 flex justify-around;
      .btn {
        @apply rounded-lg;
      }
    }
  }
}

.shipping-tips-banner {
  background-image: url('~public/images/shipping-tips/tips-banner.png');
  @apply bg-cover bg-no-repeat;
  background-position: bottom center;
  @screen md-mx {
    background-image: none;
  }
}

.primary-banner-wrapper {
  @apply bg-white-ice-three;
}
