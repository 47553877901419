@use 'sass:color';

.section-gap {
  @apply py-8 lg-mn:py-[50px];
}

.section-gap.skip-bottom--spacing {
  @apply pb-0;
}

.section-gap.skip--spacing {
  @apply py-0;
}

.why-different-section {
  .left-col {
    @apply pr-8;
    @screen md-mx {
      @apply pr-0;
    }
  }

  .right-col {
    @apply pl-8;
    @screen md-mx {
      @apply pl-0;
    }
  }
}

// Home coustomized solution section
.coustomized-solution-section {
  .feature-box.left-icon {
    @screen md-mx {
      @apply mb-3;
    }
    &.odd {
      @screen md-mx {
        @apply flex-row-reverse;
      }
    }

    .feature-box-icon {
      @screen xs {
        max-width: 140px;
        @apply flex flex-col justify-center;
      }
    }

    .feature-box-content {
      p {
        @apply text-base;
        @screen sm-mx {
          @apply text-sm;
        }
      }

      @screen md-mx {
        @apply flex flex-col justify-center;
        .title {
          @apply mb-1;
        }
      }
      @screen xs {
        width: calc(100% - 140px);
      }
    }
  }
}

.promotion-section {
  @apply flow-root lg:min-h-[546px];

  @screen sm-mx {
    background-image: url('~public/images/promotion-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% center;
    @apply py-14 mb-5 overflow-hidden;
  }
}

@media only screen and (max-width: 1920px) {
  .order-form-section {
    &.luggage {
      background-image: url('~public/images/banner/1920x575/luggage.webp');
    }

    &.box {
      background-image: url('~public/images/banner/1920x575/boxes.webp');
    }

    &.golf_box {
      background-image: url('~public/images/banner/1920x575/golf.webp');
    }

    &.skis {
      background-image: url('~public/images/banner/1920x575/skins.webp');
    }
  }
}

@media only screen and (min-width: 1921px) {
  .order-form-section {
    &.luggage {
      background-image: url('~public/images/banner/2560x575/luggage.webp');
    }

    &.box {
      background-image: url('~public/images/banner/2560x575/boxes.webp');
    }

    &.golf_box {
      background-image: url('~public/images/banner/2560x575/golf.webp');
    }

    &.skis {
      background-image: url('~public/images/banner/2560x575/skins.webp');
    }
  }
}

// booking for section
.order-form-section {
  background-color: rgba(255, 109, 10, 0.1);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  @apply ease-in-out duration-500;

  @screen sm-mx {
    background-image: none !important;
    background-color: #ffffff;
  }

  &.check-price-form-section {
    .container {
      @screen lg-mx {
        max-width: 95%;
      }
    }
  }

  .form-wrapper {
    @apply rounded-lg flex;
    background-color: rgba(#ffffff, 0.89);
    box-shadow: 0 0 10px rgba(#949494, 1);
    min-height: 510px;
    @screen ipad-mini-h {
      height: auto;
    }
    @screen md-mx {
      height: auto;
      min-height: 530px;
    }
    @screen sm-mx {
      box-shadow: none;
    }

    form.row {
      @apply w-full;
    }

    &.price-box-warp {
      @apply bg-white;
    }

    .input-group {
      label {
        @apply text-base font-bold text-black;
      }
    }

    .right-fields {
      @apply flex flex-col justify-between;
      .tab-nav-warpper {
        label {
          @apply font-medium;
        }

        .tab-nav {
          @apply flex flex-row flex-wrap mt-3;
          @screen md-mx {
            @apply grid grid-cols-4 gap-2 mr-0;
          }
          @screen sm-mx {
            @apply flex justify-start;
          }
          @screen xs {
            @apply grid;
          }
          li {
            @apply mr-3 relative;
            @screen md-mx {
              @apply mr-0;
            }
            &.luggage {
              .icon {
                background-image: url('~public/images/book-now/tab_nav_icon_01.png');
              }
            }

            &.box {
              .icon {
                background-image: url('~public/images/book-now/tab_nav_icon_02.png');
              }
            }

            &.golf_box {
              .icon {
                background-image: url('~public/images/book-now/tab_nav_icon_03.png');
              }
            }

            &.skis {
              .icon {
                background-image: url('~public/images/book-now/tab_nav_icon_04.png');
              }
            }

            .item-inner {
              @apply bg-secondary text-center flex flex-col justify-center items-center text-white border-t-4 border-opacity-50 rounded-md cursor-pointer font-semibold transition-all duration-300 ease-in-out;
              width: 100px;
              height: 80px;
              @screen xs {
                @apply w-full;
              }
              &:hover {
                @apply bg-orange-primary border-t-4;
              }

              .icon {
                width: 28px;
                height: 28px;
                background-repeat: no-repeat;
                background-position: center;
              }

              .title {
                @apply text-xs font-semibold;
              }
            }

            .selected-item {
              @apply text-xl font-bold text-orange-primary block text-center absolute;
              left: calc(50% - 14px);
            }

            &.active {
              .item-inner {
                @apply bg-orange-primary border-t-4;
              }
            }
          }
        }
      }
    }
  }
}

//  book-now-form
.book-now-form {
  &.form-wrapper {
    .left-fields {
      @apply flex flex-col justify-between rounded-tl-lg rounded-bl-lg;
      width: 304px;
      @screen sm-mx {
        @apply w-full;
      }
      .input-group {
        // @screen sm-mx {
        //     @apply flex flex-wrap justify-end;
        // }
        label {
          @apply inline-block;
          // @screen sm-mx {
          //     width: 140px;
          //     &.checkbox {
          //         width: 100px;
          //     }
          // }
        }

        .input-field,
        .react-datepicker-wrapper,
        .select-two-wrapper,
        .autocomplete-filed {
          @screen sm-mx {
            // width: calc(100% - 140px);
            @apply text-sm12;
            div[class$='placeholder'] {
              @apply text-sm12;
            }

            input::placeholder {
              @apply text-sm12;
            }
          }
        }

        &.storage-select-group {
          .labels-group {
            @apply flex justify-between;
            @screen sm-mx {
              @apply w-full justify-start;
            }
            label {
              &.checkbox {
                @apply flex items-center;
                span.title {
                  @apply text-sm11 font-semibold leading-3 text-gray-400 inline-block text-left;
                }

                .MuiCheckbox-root {
                  @apply text-sm11 font-semibold leading-3 text-gray-400;
                  padding: 0;

                  &.Mui-checked {
                    & ~ span.title {
                      @apply text-orange-primary;
                    }

                    @apply text-orange-primary;
                  }
                }

                .MuiCheckbox-root.Mui-checked ~ span {
                  @apply text-orange-primary;
                }

                span.title {
                  @screen sm-mx {
                    br {
                      @apply hidden;
                    }
                  }
                }
              }
            }
          }
        }

        select.input-field {
          @apply w-full;
        }

        .autocomplete-filed {
          .input-field {
            @apply w-full;
          }
        }

        .react-datepicker-wrapper {
          input {
            @apply w-full;
            &.has-error {
              @apply border-red-600;
            }
          }

          .react-datepicker__input-container {
            input {
              z-index: 1;
              position: relative;
              background: transparent;
            }

            &::after {
              content: '';
              position: absolute;
              width: 20px;
              height: 22px;
              top: 7px;
              right: 7px;
              z-index: 0;
              background-image: url('~public/images/calander-icon.png');
              background-size: 100% 100%;
              background-position: center;
            }
          }
        }

        &:nth-child(2) {
        }

        .error {
          @screen sm-mx {
            min-width: 50%;
            text-align: right;
          }
        }
      }

      .info-icon {
        @apply border-2 text-primary;
        font-size: 8px;
        line-height: 14px;
        width: 16px;
        height: 16px;
        margin: 0;
        transform: translate(-1px, -1px);
      }
    }

    .right-fields {
      width: calc(100% - 304px);
      @screen sm-mx {
        @apply w-full;
      }
      .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.7);
        justify-content: center;
        align-items: center;
        z-index: 11;
        display: flex;
      }

      label {
        .info-icon {
          @apply border-2 text-primary bg-white ml-1;
          font-size: 8px;
          line-height: 16px;
          width: 18px;
          height: 18px;
          transform: translate(0, -1px);
        }
      }
    }
  }

  .tab-content-wrapper {
    @screen md-mx {
      @apply pt-2;
    }
    .single-tab-content {
      /*
                @apply hidden justify-between;

                &.active {
                    @apply flex;
                }
            */

      @apply flex justify-between;
      @screen md-mx {
        @apply justify-start;
      }

      .luggage-item {
        @apply flex flex-col justify-end items-center;
        width: calc(100% / 6 - 10px);
        @screen ipad-mini-h {
          @apply mb-4;
          width: calc(100% / 3 - 10px);
        }
        @screen md-mx {
          @apply mb-4;
          width: calc(100% / 3 - 10px);
        }
        @screen xs {
          width: calc(100% / 3);
        }
        img {
          @apply mb-2;
        }

        h5 {
          @apply text-sm font-medium;
          @screen lg-mx {
            @apply text-sm11;
          }
        }

        p:nth-child(3) {
          @apply text-secondary text-sm12 font-medium;
          @screen lg-mx {
            @apply text-sm10;
          }
        }

        p:nth-child(4) {
          @apply text-primary-orange text-sm11 font-medium;
          @screen lg-mx {
            @apply text-sm10;
          }
          .popover-content-wrap {
            position: absolute;
            width: 15px;
            height: 15px;
            top: unset;
            right: unset;
          }
        }

        .luggage-content {
          @apply flex flex-col items-center;
          h5 {
            @apply text-sm13 font-bold;
            @screen lg-mx {
              @apply text-sm11;
            }
          }

          p:nth-child(2) {
            @apply text-secondary text-sm12 font-medium;
            @screen lg-mx {
              @apply text-sm10;
            }
          }

          p:nth-child(3) {
            @apply text-primary text-sm12 font-semibold relative inline-block pr-4;
            @screen lg-mx {
              @apply text-sm10;
            }
            .icon {
              @apply bg-primary-orange;
              position: absolute;
              width: 13px;
              height: 13px;
              top: 1px;
              right: 2px;
              border-radius: 50px;
              font-size: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          p:nth-child(4) {
            @apply text-primary-orange text-sm11 font-medium;
            @screen lg-mx {
              @apply text-sm10;
            }
            .popover-content-wrap {
              position: absolute;
              width: 15px;
              height: 15px;
              top: unset;
              right: unset;
            }
          }
        }

        .input-group {
          @apply mt-1;
          .input-field {
            @apply text-base font-medium leading-4 text-sas-gray-500 rounded-sm5;
            padding: 6px 30px 6px 10px;
          }

          &.has-error {
            .input-field {
              @apply border-red-600;
            }
          }
        }
      }

      &.luggage-tab {
        .luggage-item {
          &:nth-child(1) {
            width: 14%;
            @screen ipad-mini-h {
              width: 25%;
            }
            @screen md-mx {
              width: 33.3333%;
            }
            // @screen xs {
            //     width: 50%;
            // }
          }

          &:nth-child(2) {
            width: 14%;
            @screen ipad-mini-h {
              width: 25%;
            }
            @screen md-mx {
              width: 33.3333%;
            }
            // @screen xs {
            //     width: 50%;
            // }
          }

          &:nth-child(3) {
            width: 15.5%;
            @screen ipad-mini-h {
              width: 25%;
            }
            @screen md-mx {
              width: 33.3333%;
            }
            // @screen xs {
            //     width: 50%;
            // }
          }

          &:nth-child(4) {
            width: 15.2%;
            @screen ipad-mini-h {
              width: 25%;
            }
            @screen md-mx {
              width: 33.3333%;
            }
            // @screen xs {
            //     width: 50%;
            // }
          }

          &:nth-child(5) {
            width: 16%;
            @screen ipad-mini-h {
              width: 25%;
            }
            @screen md-mx {
              width: 33.3333%;
            }
            .item-thumb {
              img {
                @screen sm-mx {
                  width: 90%;
                }
              }
            }
          }

          &:nth-child(6) {
            width: 19.2%;
            @screen md-mx {
              width: 33.3333%;
            }
            .item-thumb {
              img {
                @screen sm-mx {
                  width: 90%;
                }
              }
            }
          }

          .item-thumb {
          }
        }
      }

      &.gulf_bag_row {
        .luggage-item {
          width: calc(100% / 5 - 10px);
          @screen md-mx {
            @apply mb-4;
            width: calc(100% / 3);
          }
          // @screen xs {
          //     width: 50%;
          // }
        }
      }

      &.box-tab-content {
        .item-thumb {
          img {
            max-width: 100%;
          }
        }

        .luggage-item.special-box {
          @apply self-start items-end;
          width: calc(100% / 2 - 10px);
          @screen md-mx {
            @apply mb-4 items-center;
            width: calc(100% / 1);
          }
          .panel {
            @apply rounded-sm3 overflow-hidden;
            background-color: rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 23px -4px #6d6d6d;

            .panel-heading {
              @apply bg-secondary text-white font-medium text-lg py-2;
              .info-icon {
                @apply bg-white text-gray-600 ml-2;
                font-size: 8px;
                line-height: 14px;
                width: 16px;
                height: 16px;
                transform: translate(0, -1px);
              }
            }

            .panel-body {
              @apply px-3 py-1;
              table {
                thead {
                  tr {
                    @apply flex justify-between mb-2;
                    td {
                      @apply text-sm;
                    }
                  }
                }

                tbody {
                  tr {
                    @apply grid grid-cols-5 gap-3;
                    td {
                      @apply pb-2;
                      .input-group {
                        @apply text-center;
                        input.input-field {
                          @apply px-2 py-1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .input-group {
            .input-field {
              max-width: 60px;
            }
          }
        }
      }
    }
  }

  .form-bottom {
    @apply mt-3;
    .buttons-group {
      button[type='reset'] {
        @apply rounded-sm3 px-6 py-2.5 mr-2 font-semibold;
      }

      button[type='submit'] {
        @apply rounded-sm3 px-5 py-2.5 bg-orange-primary font-semibold;
      }
    }
  }
}

// price-box-warp
.price-list-section {
  background-image: url('~public/images/price-list-bg.jpg');
  @apply bg-cover bg-no-repeat;
  @screen md-mx {
    background-image: none;
  }
  .left-fields {
    @screen md-mx {
    }
  }
}

.price-box-warp {
  @apply rounded-lg;
  background-color: rgba(#ffffff, 1);
  box-shadow: 0 0 10px rgba(#949494, 1);
  overflow: hidden;
  min-height: 528px;
  @screen md-mx {
    box-shadow: none;
  }
  .left-fields {
    box-shadow: 0 0 10px rgba(#949494, 0.75);
    @screen md-mx {
      box-shadow: none;
    }
    .left-main-title {
      @apply flex justify-between;
      h3 {
        @apply text-lg font-bold text-black;
      }

      .btn {
        @apply px-6 py-1 text-sm uppercase;
        border-radius: 3px;
        border: 1px solid #0968aa;
        @screen lg-mx {
          @apply px-3;
        }
      }
    }

    .address-list {
      li {
        @apply flex flex-wrap mb-5;
        @screen md-mx {
          @apply mb-0;
        }
        span:first-child {
          @apply font-semibold;
          width: 78px;
        }

        span:last-child {
          @apply text-sm12;
          width: calc(100% - 78px);
          text-align: left;
          @screen md-mx {
            br {
              display: none;
            }
          }
          @screen sm-mx {
            @apply text-sm13;
          }
          .info-icon {
            @apply text-gray-800;
            font-size: 8px;
            line-height: 17px;
            width: 18px;
            height: 18px;
            text-align: center;
          }
        }

        &.dropoff-date {
          span:first-child {
            @apply font-semibold;
            width: 170px;
          }
        }
      }
    }

    .item-title {
      @apply text-lg font-semibold;
    }

    .item-list {
      @apply mt-5;
      @screen md-mx {
        @apply mt-3 flex justify-center;
      }
      li {
        @apply relative flex flex-wrap mb-5;
        min-height: 42px;
        @screen md-mx {
          @apply mr-2 flex-col mb-0;
        }
        &:last-child {
          @apply mb-0;
        }

        .icon {
          @apply absolute left-0 top-0 text-center flex justify-center items-center;
          width: 42px;
          height: 42px;
          @screen md-mx {
            @apply relative;
            width: 30px;
            height: 30px;
          }
          img {
            @apply inline-block;
          }
        }

        .count {
          @apply mt-1 font-semibold text-center hidden;
          @screen md-mx {
            @apply block;
          }
        }

        .sub-items {
          @apply block pl-20 flex flex-col justify-center;
          @screen md-mx {
            @apply hidden;
          }
          span {
            @apply block text-sm;
          }
        }
      }
    }
  }

  .right-fields {
    .left-info {
      width: 60%;
      @screen md-mx {
        width: 100%;
      }
    }

    .right-info {
      width: 40%;
      padding-left: 20px;
      @screen md-mx {
        width: 100%;
      }
      @screen sm-mx {
        padding-left: 0;
      }
    }

    .right-main-title {
      h3 {
        @apply text-2xl font-bold text-black;
        // transform: translate(-115px, 0);
        @screen md-mx {
          // transform: translate(0, 0);
        }
        @screen sm-mx {
          @apply text-xl;
        }
        span.info-icon {
          @apply text-gray-800;
          transform: translateY(-3px);
        }
      }
    }

    .group-title {
      @apply text-lg text-secondary font-bold mb-3;
      .info-icon {
        @apply text-sm10 text-gray-800;
        width: 18px;
        height: 18px;
      }
    }

    .price-list-group {
      @apply mb-5;
      .price-list {
        li {
          @apply border border-sas-gray-120 rounded-md px-1 py-1.5 flex flex-wrap mb-3;
          .col {
            @apply flex flex-wrap;
            width: 50%;
            @screen sm-mx {
              @apply justify-center;
            }
            &:first-child {
              width: 55%;
            }

            &:last-child {
              width: 45%;
            }

            .inner-col {
              @apply text-sm11 text-center;
              width: 50%;
              @screen sm-mx {
                width: 100%;
              }
            }

            span {
              @apply inline-block;
            }
          }

          .date-info {
            width: 60%;

            span {
              @apply block;
              &:nth-child(1) {
                @apply font-bold;
              }

              &:nth-child(2):not(.info-icon) {
                @apply text-primary-orange;
              }

              .info-icon {
                @apply inline-block;
              }

              &:nth-child(3) {
                @apply font-bold;
              }
            }

            .package-title {
              display: inline-block;
            }

            .info-icon {
              position: absolute;
              top: 2px;
              right: -20px;
              @apply text-gray-800;
              font-size: 8px;
              line-height: 17px;
              font-weight: 100;
              width: 18px;
              height: 18px;
            }
          }

          .time-info {
            width: 40% !important;

            span {
              @apply font-bold;
            }
          }

          .price-info {
            @apply flex flex-col justify-center items-center;
            word-break: break-word;

            span {
              @apply border border-sas-gray-120 pt-1.5 pb-2 px-3 rounded-md inline-flex flex-col justify-center items-center;
              max-width: 110px;

              img {
                max-width: 52px;
              }

              strong {
                @apply inline-block mt-2 text-base font-semibold;
                line-height: 16px;

                &.small {
                  @apply text-sm11;
                }
              }
            }

            &.dropoff-price {
              span {
                @apply py-0 px-5 pb-2;
                span {
                  @apply px-0 py-0 border-none;
                }

                strong {
                  @apply mt-0.5;
                }
              }
            }
          }
        }

        &.dropoff-list {
          li {
            .price-info {
              span {
                @apply py-0 px-5 pb-2;
                span {
                  @apply px-0 py-0 border-none;
                }

                strong {
                  @apply mt-0.5;
                }
              }
            }

            .button-info {
              @apply flex justify-center items-center;
              a {
                @apply mt-0;
              }
            }
          }
        }
      }
    }

    .button-info {
      .btn {
        @apply bg-primary-orange mt-3 text-sm font-medium uppercase px-5;
        border-radius: 3px;
      }
    }

    .list-style-one {
      li {
        @apply text-sm font-medium mb-6;
        &:before {
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          background-size: 100% auto;
        }
      }
    }
  }

  .footer-info {
    .footer-left {
      width: 65%;
      @screen md-mx {
        width: 100%;
      }
    }

    .footer-right {
      width: 35%;
      @screen md-mx {
        width: 100%;
      }
    }
  }
}

// grab-our-facilities-section , for-whom-section
.grab-our-facilities-section,
.for-whom-section {
  .feature-box.style-two {
    .feature-box-content {
      .title {
        @apply text-lg;
      }
    }
  }
}

// store-documents-box
.store-documents-box {
  @apply inline-block pt-28 pb-36 px-14;
  background-image: url('~public/images/store-documents-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @screen md-mx {
    @apply pt-12 pb-12 px-0;
    background-image: none;
  }
  @screen sm-mx {
    @apply pt-6 pb-0;
  }
  .list-style-one {
    li {
      @apply pl-16 text-2xl font-medium mb-4;
      @screen md-mx {
        @apply text-xl;
      }
      @screen sm-mx {
        @apply text-sm pl-10;
      }
      &::before {
        top: 0;
        width: 35px;
        height: 32px;
        background-image: url('~public/images/hand_right.png');
        background-size: 100% 100%;
        @screen sm-mx {
          width: 20px;
          height: 16px;
        }
      }
    }
  }

  .btn {
    @screen lg-mn {
      @apply bg-sas-yellow-700 text-2xl text-primary rounded-full py-2 px-8 transition-all duration-300 ease-in-out translate-y-24;
      &:hover {
        @apply bg-secondary text-white;
      }
    }
  }
}

// login-form-box
.login-form-box,
.register-form-box {
  box-shadow: 10px 10px 78px rgba(#4f4f56, 0.1);

  .form-left {
    .section-title {
      @apply mb-12;
      h1 {
        @apply text-3xl font-semibold mb-1;
        @screen xs {
          @apply text-2xl;
        }
      }

      p {
        @apply text-base text-sas-gray-100;
        @screen xs {
          @apply text-sm;
        }
      }
    }

    .input-group {
      @apply mb-8 relative;
      max-width: 330px;
      @screen md-mx {
        max-width: 100%;
      }
      &:last-child {
        @apply mb-0;
      }

      &.checkbox-group {
        span.MuiCheckbox-root {
          margin-left: -10px;
        }

        @apply flex items-center;
        p {
          @apply text-sm;
          color: #888888;
        }
      }

      .input-field {
        @apply transition-all duration-500 ease-in-out;
        width: 100%;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid #d7d7d7;
        padding: 12px 0;

        &:placeholder {
          color: #878787;
        }

        &:focus {
          border: none;
          @apply border-t-0 border-l-0 border-r-0 border-b-2 border-solid border-secondary;
          box-shadow: none;
          outline: none;
        }
      }

      .text-view {
        @apply absolute cursor-pointer block;
        content: '';
        right: 5px;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        line-height: 20px;

        &.active {
          @apply text-secondary;
        }
      }

      &.has-error {
        .input-field {
          @apply border-red-600;
        }

        span.error {
          @apply text-sm font-bold text-red-600;
        }
      }

      button[type='submit'].btn {
        @apply text-lg font-bold;
        height: 48px;
        box-shadow: 5px 5px 20px rgba(#1d39a6, 0.3);
      }
    }
  }

  .form-right {
  }

  .form-fields {
    @apply relative;
    .loading {
      @apply absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 100px;
      }
    }
  }
}

.register-form-box {
  .form-left {
    .section-title {
      @apply mb-8;
    }

    .input-group {
      @apply mb-4 relative;
      .input-field {
        &.select-two-wrapper {
          @apply pb-0;
          [class$='control'] {
            border: none;
            box-shadow: none;

            &:hover {
              box-shadow: none;
            }

            [class$='ValueContainer'] {
              @apply pl-0;
            }

            [class$='IndicatorsContainer'] {
              @apply pl-0;
              [class$='indicatorSeparator'] {
                display: none;
              }
            }
          }
        }
      }

      &.checkbox-group {
        &.has-error {
          span.MuiCheckbox-root {
            @apply text-red-600;
          }
        }
      }
    }
  }
}

// moving-why-different-section
.moving-why-different-section {
  .list-style-one {
    li {
      @apply pl-8;
    }
  }

  .btn.get-started {
    @screen md-mn {
      @apply text-2xl py-2 px-6 inline-block mt-4;
    }
  }
}

.moving-prom.promotion-section {
  .promotion-box {
    .promotion-inner {
      .promotion-content {
        h2 {
          @apply text-5xl;
          @screen md-mx {
            @apply text-xl;
          }
        }
      }
    }
  }
}

// storage-features-section
.storage-features-section {
  .features-contents {
    .section-title {
      @apply block;
      p {
        @apply text-xl;
      }
    }

    .list-style-one {
      li {
        @apply mb-3 text-xl font-semibold;
        @screen sm-mx {
          @apply text-lg;
        }
      }
    }
  }
}

// our-specialty-section
.our-specialty-section {
  .list-style-one {
    @apply mr-10;
    &:last-child {
      @apply mr-0;
    }

    @screen lg-mx {
      margin-right: 10px;
    }
    li {
      @apply text-2xl font-medium mb-3;
      @screen lg-mx {
        @apply text-lg;
      }
      @screen sm-mx {
        @apply text-base;
      }
    }
  }
}

// smart-storage-checklist-section
.smart-storage-checklist-section {
  .image-dev {
    @apply flex items-center;
  }

  .content-div {
    h4 {
      @apply text-2xl font-semibold text-secondary mb-5;
      @screen sm-mx {
        @apply text-xl;
      }
    }

    ul {
      li {
        @apply text-lg font-medium;
        &:before {
          top: 5px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

// general-faq-section
.general-faq-section {
  .MuiPaper-root {
    box-shadow: none;
    margin-bottom: 20px;

    &:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      @apply px-1.5;
      border-bottom: 1px solid rgba(#000000, 0.1);

      .MuiAccordionSummary-content {
        @apply text-lg font-medium;
        p {
          @apply text-lg font-medium;
        }

        color: #606060;
        @screen xs {
          @apply text-base;
        }
      }

      &.Mui-expanded {
        .MuiAccordionSummary-content {
          @apply text-secondary;

          p {
            @apply text-secondary;
          }
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        @apply text-secondary;
      }
    }

    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        @apply px-1.5 py-3;
      }
    }
  }

  &.admin-faq {
    .MuiPaper-root {
      @screen sm-mx {
        @apply mb-1;
      }
      &.Mui-expanded {
        margin-top: 0;
        border: 1px solid #ddd;
      }

      .MuiAccordionSummary-root {
        .MuiAccordionSummary-content {
          @screen sm-mx {
            @apply text-sm py-3;
            p {
              @apply text-sm;
            }
          }
        }
      }
    }
  }
}

// why-not-to-store-section
.why-not-to-store-section {
  .feature-box {
    .feature-box-icon,
    .feature-box-content {
      @screen sm-mx {
        @apply w-full;
      }
    }

    .feature-box-content {
      .title {
        @screen sm-mx {
          @apply mb-0;
        }
      }
    }
  }
}

// safe-services-section
.safe-services-section {
  .icon-list {
    li {
      background-color: rgba(#ffffff, 0.7);
    }
  }
}

// what-you-should-know
.what-you-should-know,
.benefits-section {
  .feature-box {
    .feature-box-content {
      .title {
        @screen md-mx {
          height: 56px;
        }
        @screen xs {
          height: auto;
        }
      }
    }
  }
}

.dashboard-statistics {
  //background: rgba(#f4f7fc, 0.99);
  @screen sm-mx {
    background: none;
  }

  .my-shipment-section {
    background: rgba(#ffffff, 0.99);
  }

  .my-storage-section {
    background: rgba(#ffffff, 0.99);
  }

  .category-icon {
    width: 80px;

    img {
      width: 80px;
      height: 70px;
      box-shadow: 0 0 10px 2px rgba(50, 190, 63, 0.2);

      @screen lg {
        width: 78px;
        height: 66px;
      }
    }
  }

  .my-storage-icon-parent {
    width: 20px;
    height: 32px;
    position: relative;
    margin-top: -10px;

    img {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
    }
  }
}

li.profile {
  .account-name,
  .account-number {
    // span{
    //     @apply inline-block ;
    // }
  }
}

.contact-form-section {
  @screen md-mx {
    .container {
      @apply px-0;
    }
  }
}

.contact-form-warp {
  .left-side,
  .right-side {
    padding-top: 75px;

    .title {
      @apply font-semibold;
      font-size: 45px;
      line-height: 56px;
      @screen md-mx {
        font-size: 35px;
      }
    }
  }

  .left-side {
    background-color: #f6fafb;
    padding-left: 40px;
    padding-right: 40px;
    @screen md-mx {
      padding-top: 40px;
      padding-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .loading {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .contact-form {
      margin-top: 35px;

      .input-group {
        &.has-error {
          input,
          textarea {
            @apply border border-solid border-red-600;
          }
        }

        input,
        textarea {
          @apply w-full border-none text-lg text-primary;
          padding: 18px 15px;

          &:focus {
            @apply border-blue-300 ring ring-blue-300 ring-opacity-50;
          }
        }

        textarea {
          min-height: 180px;
        }

        .submit-btn {
          @apply text-lg font-semibold;
          width: 180px;
          height: 56px;
          @screen sm-mx {
            width: 100%;
          }
        }
      }
    }
  }

  .right-side {
    @apply bg-secondary relative;
    padding-left: 45px;
    padding-right: 20px;
    padding-bottom: 75px;

    @screen xl-min {
      &::after {
        content: '';
        width: 332px;
        height: 233px;
        position: absolute;
        top: -233px;
        left: 20px;
        background-image: url('~public/images/contact-info-seciton-image.png');
      }
    }

    @screen md-mx {
      padding-top: 30px;
      padding-left: 25px;
      padding-right: 25px;
    }
    .title {
      @apply text-white;
    }

    .contact-info {
      @apply text-white mt-14;
      @screen md-mx {
        @apply mt-7;
      }
      li {
        @apply mb-7 relative;
        padding-left: 70px;

        &::after {
          content: '';
          width: 60px;
          height: 60px;
          position: absolute;
          top: 4px;
          left: 0;
          background-image: url('~public/images/location-icon-2.png');
          background-repeat: no-repeat;
          background-position: top center;
        }

        &.phone::after {
          background-image: url('~public/images/phone-icon-4.png');
        }

        &.phone::after {
          background-image: url('~public/images/phone-icon-4.png');
        }

        &.time::after {
          background-image: url('~public/images/calander-icon-2.png');
        }

        .info-title {
          @apply text-2xl font-medium block mb-4;
        }

        address {
          @apply not-italic text-base;
        }
      }
    }
  }

  .captcha-image {
    @apply bg-white text-center;
    padding: 7px 0;
    @screen sm-mx {
      @apply flex flex-col justify-center;
    }
    img {
      @apply inline-block;
    }
  }

  .btn.btn-primary.change-captch-btn {
    @apply block py-5 w-full;
    margin-top: 1px;
    @screen sm-mx {
      @apply px-1;
    }
  }
}

.boxList {
  .boxDiv {
    background: #ecf0df;
  }

  &:nth-child(2n + 2) {
    .boxDiv {
      background: #dbecf8;
    }
  }
}
